import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { universalSearchStyles } from "./style";

import GetImages from "../../../components/GetImages";
import ChipSelect from "../../../components/ChipSelect";
import IPDashboardCard from "../../../components/IPDashboard/IPDashboardCard";
import AppointmentDetailsView from "../../../components/AppointmentDetails/AppointmentDetailsView";
import DiagnosticList from "../../../components/DiagnosticList";
import FullScreenDialog from "../../../components/FullScreenDialog";
import DiagnosticTestList from "../../../components/DiagnosticTestList";
import OrderModals from "../../PharmacyClinicDiagnostic/OrderModals";
import HealthHubSearchList from "../../Patient/HealthHub/HealthHubSearchList";
import FindDoctorCard from "../../../components/FindDoctorCard";
import MedicineCard from "../../../components/AllOrders/MedicineCard";
import AppointmentCard from "../../../components/AllOrders/AppointmentCard";
import TestBookingCard from "../../../components/AllOrders/TestBookingCard";
import OrderCard from "../../PharmacyClinicDiagnostic/OrderCard";
import { InputField } from "../../../components/InputField";
import ListWithIntersection from "../../../components/ListWithIntersection";

import {
  IAppointmentCardDetailEnum,
  IDoctorScheduleConsultationType,
  IOrderDateRangeParams,
  IUniversalSearchEnum,
  IUniversalSearchResultCounts,
} from "../../../models/doctor";
import { IChipVariant } from "../../../models/button";
import { IAppointments } from "../../../models/ApiRequestResponse/doctor";
import { IRoleType } from "../../../models/role";
import { IPharmacyDiagnosticOrderDetail } from "../../../models/ApiRequestResponse/diagnostic";
import { IMedicineOrderType } from "../../../models/ApiRequestResponse/patient";

import useDebounce from "../../../utils/hooks/useDebouncer";
import {
  getDateFormat,
  getLocation,
  patientChipSearchList,
  doctorChipSearchList,
  universalSearchType,
  diagnosticChipSearchList,
  isClinic,
} from "../../../utils";
import { storage } from "../../../utils/Storage";
import { INotifyEnum, notify } from "../../../utils/toaster";
import usePagination from "../../../utils/hooks/usePagination";
import useUniversalSearchChip from "../../../utils/hooks/useUniversalSearchChip";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDoctorHospitalListing,
  fetchDoctorHospitalPatientListing,
  resetAdmitPatients,
} from "../../../store/slices/doctor/ipDashboard";
import {
  fetchSeachedAppointmentList,
  resetSearchAppointments,
} from "../../../store/slices/doctor/schedule";
import {
  fetchMoreOrders,
  fetchPharmacySearchOrdersList,
  fetchSearchDiagnosticOrdersList,
  saveSelectedOrder,
  updateSearchOrderList,
} from "../../../store/slices/pharmacyClinicDiagnostic";
import {
  fetchDiagnosticCenterList,
  fetchDiagnosticTestList,
} from "../../../store/slices/patient/tests";
import { setIsShowUniversalSearch } from "../../../store/slices/config";
import {
  resetHealthHubSearch,
  searchHealthHubData,
} from "../../../store/slices/patient/healthHub";
import {
  fetchSearchDoctors,
  resetSearchedDoctors,
} from "../../../store/slices/patient/findDoctor";
import {
  fetchAllOrdersList,
  setAllOrders,
} from "../../../store/slices/patient/home";

const UniversalSearch: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState<
    IUniversalSearchEnum | string
  >("");
  const [search, setSearch] = useState<string>("");
  const [resultCount, setResultCount] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();
  const { selectedHospitalId } = useAppSelector((state) => state.ipDashboard);
  const { searchAppointmentsList } = useAppSelector((state) => state.schedule);
  const { searchDoctors } = useAppSelector((state) => state.findDoctor);
  const { allOrders } = useAppSelector((state) => state.home);
  const { searchOrders: pharmacyOrderSearchList } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );
  const { centerList, centreDetails, testList } = useAppSelector(
    (state) => state.tests
  );
  const { searchData } = useAppSelector((state) => state.healthHub);
  const { hospitalPatients } = useAppSelector((state) => state.ipDashboard);

  const isClinicRole = isClinic();
  const { pharmacyChipSearchList } = useUniversalSearchChip();

  const calculateSearchResultCount = () => {
    const healthHubResultCount =
      (searchData?.result?.playlist?.length || 0) +
      (searchData?.result?.video?.length || 0);
    return {
      [IUniversalSearchEnum.APPOINTMENTS]: searchAppointmentsList?.length,
      [IUniversalSearchEnum.PHARMACY_ORDER]: pharmacyOrderSearchList?.length,
      [IUniversalSearchEnum.FIND_DOCTORS]: searchDoctors?.length,
      [IUniversalSearchEnum.TEST]: testList?.length,
      [IUniversalSearchEnum.DIAGNOSTIC]: centerList?.length,
      [IUniversalSearchEnum.HEALTH_HUB]: healthHubResultCount ?? 0,
      [IUniversalSearchEnum.CLINICAL_TEAM]: 0,
      [IUniversalSearchEnum.CLINIC_APPOINTMENTS]:
        searchAppointmentsList?.length,
      [IUniversalSearchEnum.ORDERS]: allOrders?.length,
      [IUniversalSearchEnum.IN_PATIENTS]: hospitalPatients?.length,
      [IUniversalSearchEnum.DIAGNOSTIC_CENTER]: pharmacyOrderSearchList?.length,
    };
  };

  const searchResultCounter = calculateSearchResultCount();
  const [searchResultCounts, setSearchResultCount] =
    useState<IUniversalSearchResultCounts | null>(searchResultCounter);

  const today = new Date();
  const day = getDateFormat(today.toDateString(), "ddd");
  const debouncedSearch = useDebounce(search, 500);

  const roleType = storage.getSessionToken("roleType");
  const locationVal = getLocation();

  const { handleFetch, handleFetchNext, handleResetOffset } =
    usePagination<IOrderDateRangeParams>({
      fetchFn: fetchPharmacySearchOrdersList,
      fetchNextFn: fetchMoreOrders,
    });

  const {
    handleFetch: fetchSearchDoctorsList,
    handleFetchNext: fetchSearchDoctorsListNext,
  } = usePagination<any>({
    fetchFn: fetchSearchDoctors,
    fetchNextFn: fetchSearchDoctors,
  });

  const {
    handleFetchNext: handleFetchHealthHubNextData,
    handleFetch: handleFetchHealthHubData,
    offset,
  } = usePagination<any>({
    fetchFn: searchHealthHubData,
    fetchNextFn: searchHealthHubData,
  });

  const {
    handleFetch: handleFetchAllOrderData,
    handleFetchNext: handleFetchNextAllOrderData,
  } = usePagination<any>({
    fetchFn: fetchAllOrdersList,
    fetchNextFn: fetchAllOrdersList,
  });

  const resetUniversalSearchHandler = () => {
    handleResetOffset();
    setSearchResultCount(null);
    setResultCount(null);
    switch (roleType) {
      case IRoleType.PATIENT:
        dispatch(resetHealthHubSearch());
        dispatch(resetSearchedDoctors());
        dispatch(setAllOrders([]));
        return;
      case IRoleType.DOCTOR:
      case IRoleType.JR_DOCTOR:
        dispatch(resetAdmitPatients());
        dispatch(resetSearchAppointments());
        return;
      case IRoleType.PHARMACY:
      case IRoleType.DIAGNOSTIC_CENTER:
        dispatch(resetSearchAppointments());
        dispatch(saveSelectedOrder(null));
        dispatch(updateSearchOrderList([]));
        return;
      default:
        return null;
    }
  };

  const closeUniversalSearchHandler = () => {
    dispatch(setIsShowUniversalSearch(false));
    resetUniversalSearchHandler();
  };

  const patientAppointmentsScreen = useCallback(() => {
    return (
      <Box
        sx={{
          ...universalSearchStyles.cardContainer,
          ...universalSearchStyles.pharmacyOrDiagnosesCardContainer,
        }}
      >
        {searchAppointmentsList?.map(
          (appointments: IAppointments, index: number) => (
            <AppointmentDetailsView
              key={index}
              type={IAppointmentCardDetailEnum.SEARCH}
              {...appointments}
              isMobile={false}
            />
          )
        )}
      </Box>
    );
  }, [searchAppointmentsList]);

  const universalSearchHandler = () => {
    if (search.length === 0) return;
    switch (selectedValue) {
      case IUniversalSearchEnum.APPOINTMENTS:
      case IUniversalSearchEnum.CLINIC_APPOINTMENTS:
        dispatch(fetchSeachedAppointmentList(search));
        return;
      case IUniversalSearchEnum.DIAGNOSTIC_CENTER:
        dispatch(fetchSearchDiagnosticOrdersList({ keyword: search }));
        return;
      case IUniversalSearchEnum.IN_PATIENTS:
        dispatch(
          fetchDoctorHospitalPatientListing({
            hospitalId: selectedHospitalId,
            keyword: search,
          })
        );
        return;
      case IUniversalSearchEnum.CLINICAL_TEAM:
        setResultCount(null);
        return null;
      case IUniversalSearchEnum.PHARMACY_ORDER:
        return handleFetch({
          isPending: false,
          keyword: search,
        });

      case IUniversalSearchEnum.FIND_DOCTORS:
        return fetchSearchDoctorsList({ search: search });
      case IUniversalSearchEnum.ORDERS:
        dispatch(setAllOrders([]));
        handleFetchAllOrderData({ orderType: "", keyword: search });
        return;
      case IUniversalSearchEnum.DIAGNOSTIC:
        const requestDiagnosticData = {
          latitude: locationVal?.latitude || 28.610802,
          longitude: locationVal?.longitude || 77.371909,
          day: day,
          search: search,
        };
        dispatch(fetchDiagnosticCenterList(requestDiagnosticData));
        return;
      case IUniversalSearchEnum.TEST:
        if (centreDetails?.user_id) {
          const requestTestData = {
            diagnosticId: centreDetails && centreDetails?.user_id,
            search: search,
          };
          dispatch(fetchDiagnosticTestList(requestTestData));
        }
        return;
      case IUniversalSearchEnum.HEALTH_HUB:
        handleFetchHealthHubData({ searchTerm: search, offset: 1 });
        return;
      default:
        return null;
    }
  };

  const allOrderList = useMemo(() => {
    return allOrders?.map((order, index) => {
      if (
        order.type === IMedicineOrderType.HOME_DELIVERY ||
        order.type === IMedicineOrderType.PICKUP
      ) {
        return <MedicineCard {...order} key={`order-${index}`} />;
      } else if (
        order.consultation_type === IDoctorScheduleConsultationType.IN_CLINIC ||
        order.consultation_type === IDoctorScheduleConsultationType.VIDEO_CALL
      ) {
        return <AppointmentCard {...order} key={`order-${index}`} />;
      } else {
        return <TestBookingCard {...order} key={`order-${index}`} />;
      }
    });
  }, [allOrders]);

  const searchList = useMemo(() => {
    const handlePharmacyOrDiagnosticOrder = (onLoadMore: () => void) => (
      <ListWithIntersection
        direction="row"
        list={pharmacyOrderSearchList ?? []}
        onLoadMore={onLoadMore}
      >
        {pharmacyOrderSearchList?.map(
          (item: IPharmacyDiagnosticOrderDetail, id: number) => (
            <ListWithIntersection.Item key={item.id} dataId={item.id}>
              <OrderCard
                {...item}
                onSelect={(order) => {
                  setOpenModal((state) => !state);
                  dispatch(saveSelectedOrder(order));
                }}
              />
            </ListWithIntersection.Item>
          )
        )}
      </ListWithIntersection>
    );

    switch (roleType) {
      case IRoleType.DOCTOR:
        switch (selectedValue) {
          case IUniversalSearchEnum.APPOINTMENTS:
            return <>{patientAppointmentsScreen()}</>;
          case IUniversalSearchEnum.IN_PATIENTS:
            return (
              <Box
                sx={{
                  ...universalSearchStyles.cardContainer,
                  ...universalSearchStyles.pharmacyOrDiagnosesCardContainer,
                }}
              >
                <IPDashboardCard />
              </Box>
            );
          default:
            return null;
        }
      case IRoleType.PATIENT:
        switch (selectedValue) {
          case IUniversalSearchEnum.FIND_DOCTORS:
            return (
              <>
                {searchDoctors?.length > 0 ? (
                  <Box sx={universalSearchStyles.cardContainer}>
                    {searchDoctors?.map((list: any, index: number) => (
                      <FindDoctorCard
                        {...list}
                        currentIndex={index}
                        key={index}
                      />
                    ))}
                  </Box>
                ) : null}
              </>
            );
          case IUniversalSearchEnum.ORDERS:
            return (
              <>
                {allOrders?.length > 0 ? (
                  <Grid item xs={12} lg={5}>
                    <ListWithIntersection
                      list={allOrderList}
                      onLoadMore={() =>
                        handleFetchNextAllOrderData({
                          orderType: "",
                          keyword: search,
                        })
                      }
                      muiClasses={{
                        ...universalSearchStyles.cardContainer,
                        maxHeight: "90vh",
                      }}
                    >
                      {allOrderList?.map((item, index) => (
                        <ListWithIntersection.Item
                          key={`Item-${index}`}
                          dataId={`Item-${index}`}
                        >
                          {item}
                        </ListWithIntersection.Item>
                      ))}
                    </ListWithIntersection>
                  </Grid>
                ) : null}
              </>
            );
          case IUniversalSearchEnum.DIAGNOSTIC:
            return centerList?.length > 0 ? <DiagnosticList /> : null;

          case IUniversalSearchEnum.HEALTH_HUB:
            return (
              <HealthHubSearchList
                onCardClick={() => dispatch(setIsShowUniversalSearch(false))}
                onLoadMore={() =>
                  handleFetchHealthHubNextData({
                    searchTerm: search,
                    offset: offset + 1,
                  })
                }
              />
            );

          case IUniversalSearchEnum.TEST:
            if (!centreDetails?.user_id) <></>;
            return <DiagnosticTestList />;
          default:
            return null;
        }
      case IRoleType.PHARMACY:
        switch (selectedValue) {
          case IUniversalSearchEnum.PHARMACY_ORDER:
            return handlePharmacyOrDiagnosticOrder(() => handleFetchNext({}));
          case IUniversalSearchEnum.CLINIC_APPOINTMENTS:
            return <>{patientAppointmentsScreen()}</>;
          default:
            return null;
        }
      case IRoleType.DIAGNOSTIC_CENTER:
        return handlePharmacyOrDiagnosticOrder(() => handleFetchNext({}));
      default:
        return null;
    }
  }, [
    roleType,
    searchAppointmentsList,
    centerList,
    selectedValue,
    offset,
    search,
    pharmacyOrderSearchList,
    searchDoctors,
    allOrders,
  ]);

  const chipList = useMemo(() => {
    return roleType === IRoleType.DOCTOR
      ? doctorChipSearchList
      : roleType === IRoleType.PHARMACY
      ? pharmacyChipSearchList(isClinicRole!)
      : roleType === IRoleType.DIAGNOSTIC_CENTER
      ? diagnosticChipSearchList
      : patientChipSearchList;
  }, [isClinicRole]);

  useEffect(() => {
    if (debouncedSearch) {
      universalSearchHandler();
    } else if (search.length === 0) {
      resetUniversalSearchHandler();
    }

    if (
      selectedValue === IUniversalSearchEnum.TEST &&
      !centreDetails?.user_id
    ) {
      notify(INotifyEnum.ERROR, "Please first select diagnostic center");
    }
  }, [debouncedSearch, selectedValue]);

  useEffect(() => {
    const defaultValue =
      roleType === IRoleType.DOCTOR
        ? doctorChipSearchList[0]?.value
        : roleType === IRoleType.PHARMACY
        ? pharmacyChipSearchList(isClinicRole!)[0]?.value
        : roleType === IRoleType.DIAGNOSTIC_CENTER
        ? diagnosticChipSearchList[0]?.value
        : patientChipSearchList[0]?.value;

    if (roleType === IRoleType.DOCTOR) {
      dispatch(fetchDoctorHospitalListing());
    }
    setSelectedValue(defaultValue);
    if (roleType === IRoleType.DOCTOR) {
      dispatch(fetchDoctorHospitalListing());
    } else if (roleType === IRoleType.PATIENT) {
      const requestData = {
        latitude: locationVal?.latitude || 28.610802,
        longitude: locationVal?.longitude || 77.371909,
        day: day,
      };
      dispatch(fetchDiagnosticCenterList(requestData));
      if (centreDetails?.user_id) {
        const requestTestData = {
          diagnosticId: centreDetails && centreDetails?.user_id,
        };
        dispatch(fetchDiagnosticTestList(requestTestData));
      }
    }
  }, [roleType]);

  useEffect(() => {
    if (
      searchAppointmentsList.length > 0 ||
      centerList.length > 0 ||
      allOrders.length > 0 ||
      pharmacyOrderSearchList.length > 0 ||
      searchDoctors.length > 0 ||
      testList.length > 0 ||
      hospitalPatients.length > 0 ||
      searchData?.result?.length > 0
    ) {
      const newResultCount = calculateSearchResultCount();
      if (JSON.stringify(newResultCount) !== JSON.stringify(resultCount)) {
        setSearchResultCount((prevObject) => ({
          ...prevObject,
          ...newResultCount,
        }));
        setResultCount(
          newResultCount[selectedValue as keyof IUniversalSearchResultCounts]
        );
      }
    } else {
      setResultCount(null);
    }
  }, [
    searchAppointmentsList,
    centerList,
    allOrders,
    pharmacyOrderSearchList,
    searchDoctors,
    testList,
    hospitalPatients,
    searchData,
    search,
    selectedValue,
  ]);

  return (
    <>
      <FullScreenDialog>
        <Grid item sx={universalSearchStyles.container}>
          {/* header desktop & tablet */}
          <Box sx={universalSearchStyles.searchHeaderContainer}>
            <input
              value={search}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearch(event.target.value)
              }
              placeholder={`Search ${universalSearchType[selectedValue]} here`}
              autoFocus
            />
            <Grid item sx={universalSearchStyles.searchIcon}>
              <GetImages name="NavSearchIcon" width="24" height="24" />
            </Grid>
            <Grid item sx={universalSearchStyles.closeIconContainer}>
              <IconButton
                sx={universalSearchStyles.closeButton}
                onClick={closeUniversalSearchHandler}
              >
                <GetImages
                  name="UniSearchDesktopCloseIcon"
                  width="20"
                  height="20"
                />
              </IconButton>
            </Grid>
          </Box>
          {/* header mobile */}
          <Box sx={universalSearchStyles.searchMobileContainer}>
            <InputField
              fullWidth
              placeholder="Type here"
              value={search}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearch(event.target.value)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search.length > 0 ? (
                      <CloseIcon
                        sx={universalSearchStyles.searchCloseMobile}
                        onClick={closeUniversalSearchHandler}
                      />
                    ) : (
                      <GetImages name="NavSearchIcon" width="24" height="24" />
                    )}
                  </InputAdornment>
                ),
              }}
              autoFocus
            />
          </Box>
          <Grid item sx={universalSearchStyles.chipSelectContainer}>
            <ChipSelect
              variant={IChipVariant.ROUNDED}
              data={chipList}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              showCarousel
            />
          </Grid>
          <Grid item sx={universalSearchStyles.searchedResultContainer}>
            {searchResultCounts && (
              <Typography
                component={"p"}
                sx={universalSearchStyles.searchResultCount}
              >
                {resultCount ?? 0} records
              </Typography>
            )}
            {searchList}
          </Grid>
        </Grid>
      </FullScreenDialog>
      <OrderModals isOpen={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default UniversalSearch;
