export const APIEndpoint = {
  onBoarding: {
    login: "user-service/v1/user/login",
    otpVerify: "user-service/v1/user/verify-otp",
  },
  patient: {
    profile: `user-service/v1/user/patient-profile`,
    updateMobileChangeOtp: `user-service/v1/user/patient-phone-otp`,
    patientMember: "user-service/v1/patient-member",
    doctorProfile: "doctor-service/v1/doctor/doctor-profile",
    specialitiesList: "doctor-service/v1/doctor/specialities",
    findDoctor: "doctor-service/v1/doctor/doctors?limit=100&offset=0",
    doctor: "doctor-service/v1/doctor/doctors",
    bookingAppointment: "booking-service/v1/booking-appointment",
    postReview: "booking-service/v1/review",
    home: "user-service/v1/user/home/detail/",
    faqTags: "user-service/v1/admins/faq/tag",
    faq: "user-service/v1/admins/faq?tag=",
    healthAssessmentList:
      "user-service/v1/admins/assessments?banner=true&type=",
    healthAssessmentQuestionList: "user-service/v1/admins/assessments/",
    healthAssessmentResult: "user-service/v1/admins/assessments/result",
    allOrders: "booking-service/v1/order-medicine",
    pharmacyList: "doctor-service/v1/pharmacy/list?limit=100&offset=0&",
    patientAddress: "user-service/v1/patient-address",
    patientMedicalRecords: "booking-service/v1/medical-record/prescription",
    patientMedicalRecordsByMemberId:
      "booking-service/v1/medical-record/prescription?memberId=",
    medicineOrderPlaced: "booking-service/v1/order-medicine",
    diagnosticTestList: "doctor-service/v1/diagnostic/test-list",
    diagnosticCenterList: "doctor-service/v1/diagnostic/list",
    healthHub: "user-service/v1/admins/health/playlist/home",
    healthHubSearch: "user-service/v1/admins/health/video",
    addAndGetCart: "doctor-service/v1/diagnostic/cart",
    testBooking: "booking-service/v1/test-booking",
    getBanner: "/user-service/v1/banner",
    healthHubCategories: "user-service/v1/admins/health-hub/tag",
    healthHubApplyCategoryFilter: "user-service/v1/admins/health/playlist/home",
    healthHubLikeVideo: "user-service/v1/admins/health/video/like",
    healthHubFavouriteVideo: "user-service/v1/admins/health/video/favorite",
    healthHubIncrementViewCount: "user-service/v1/admins/health/video/view",
    healthHubIncrementBlogCount: "user-service/v1/admins/health-hub/blog/view",
    healthHubBlog: "user-service/v1/admins/health-hub/blog/favorite",
    helpMeChooseDoctor: "user-service/v1/support-ticket/patient",
  },
  doctor: {
    config: `doctor-service/v1/config`,
    availabilitySetting: `doctor-service/v1/schedule-settings`,
    clinicList: `doctor-service/v1/doctor/clinic`,
    scheduleList: `doctor-service/v1/schedule`,
    scheduleSlots: `doctor-service/v1/schedule-slot`,
    checkNumber: "user-service/v1/user/search-patient",
    sendOtpPatientCreation: "user-service/v1/user/create-patient-otp",
    createPatient: "user-service/v1/user/create-patient",
    cancelScheduleAppointment:
      "booking-service/v1/booking-appointment/bulk-delete",
    doctorReviewList: "booking-service/v1/review?page=1&limit=1000",
    reviewReply: "booking-service/v1/review/reply",
    reschedule: "booking-service/v1/booking-appointment/reschedule",
    prescriptionConfig: "booking-service/v1/config",
    createAndUpdatePrescription: "booking-service/v1/prescription",
    searchMedicineTest: "booking-service/v1/",
    medicalRecordList: "booking-service/v1/medical-record",
    saveMedicalRecord: "booking-service/v1/prescription/medical-record",
    uploadReportToS3: "booking-service/v1/s3-upload",
    searchPrescriptionAttribute: "booking-service/v1/prescription-attribute",
    searchAppointments: "booking-service/v1/booking-appointment/search",
    prescriptionTemplate: "booking-service/v1/prescription-template",
    doctorHospitalList: "doctor-service/v1/admins/doctor-hospital",
    doctorHospitalPatientList:
      "doctor-service/v1/hospital-patient?page=1&limit=1000",
    hospitalRooms: "doctor-service/v1/admins/hospital/room",
    admitPatientOrDischarged: "doctor-service/v1/hospital-patient",
    admitPatientDetail: "doctor-service/v1/hospital-patient/patient",
    getActivePrescription: "booking-service/v1/prescription/active",
    getLastPrescription: "booking-service/v1/prescription/last",
    agoraRTCToken: "booking-service/v1/agora",
    agoraRTMToken: "booking-service/v1/agora-v2-token",
    doctorAssociatedTeams: "user-service/v1/user/doctor-team",
    pushCallNotification: "user-service/v1/notification/firebase",
    helpSupportTicket: "user-service/v1/support-ticket",
  },
  pharmacy: {
    orders: "booking-service/v1/order-medicine/pharmacy",
    updateOrder: "booking-service/v1/order-medicine",
    profile: "user-service/v1/user/pharmacy-profile",
    sales: "booking-service/v1/order-medicine/sales",
    salesExport: "booking-service/v1/order-medicine/export",
  },
  diagnostic: {
    orders: "booking-service/v1/test-booking/list",
    updateOrder: "booking-service/v1/test-booking",
    profile: "user-service/v1/user/diagnostic-profile",
    sales: "booking-service/v1/test-booking/sales",
    salesExport: "booking-service/v1/test-booking/export",
  },
  searchMember: "user-service/v1/user/search-user",
  accessToken: "user-service/v1/user/access-token",
  logout: "user-service/v1/user/logout",
  guestUserLogin: "user-service/v1/user/guest-login",
};

export enum IAPIStatusType {
  SUCCESS = "success",
  ERROR = "error",
}
export enum IAPIStatusCode {
  INTERNAL_SERVER_ERROR = 500,
  UNAUTHENTICATED = 401,
  SUCCESS = 200,
  FORBIDDEN = 403,
  BAD_REQUEST = 400,
}

export const messages = {
  somethingWentWrong: "Something went wrong",
};
