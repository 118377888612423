import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import Button from "../../../../components/Button";
import GetImages from "../../../../components/GetImages";
import Confirmation from "../../../../components/Confirmation";
import PaymentDetail from "../../../../components/Pharmacy/PaymentDetail";
import Reports from "../../../../components/Pharmacy/Reports";
import CreateMedicalReport from "../../../../components/CreateMedicalReport";

import { IOrderStatusEnum } from "../../../../models/ApiRequestResponse/pharmacy";
import { IRoleType } from "../../../../models/role";
import { IButtonVariant } from "../../../../models/button";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setIsPendingOrderRequestsOpen,
  setIsViewPrescriptionOpen,
} from "../../../../store/slices/pharmacyClinicDiagnostic";
import { setUploadPrescriptionOpenClosed } from "../../../../store/slices/patient/medicine";

import {
  capitalizeFirstLetter,
  convertTimestampToDDMMM,
  getMedicalReportDocumentName,
  transformDateToMMDDYYY,
} from "../../../../utils";
import { storage } from "../../../../utils/Storage";

interface IProps {
  onUpdate: (order: IOrderStatusEnum) => void;
  onComplete: () => void;
}

const OrderDetails: React.FC<IProps> = ({ onComplete, onUpdate }) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const roleType = storage.getSessionToken("roleType");

  const dispatch = useAppDispatch();
  const { order } = useAppSelector((state) => state.pharmacyClinicDiagnostic);
  const { isUploadPrescriptionOpen } = useAppSelector(
    (state) => state.medicine
  );

  const viewPrescriptionHandler = () => {
    dispatch(setIsViewPrescriptionOpen());
  };

  const openCloseConfirmModal = () => setIsConfirm(!isConfirm);

  const openClosedUploadedPrescriptionModal = () =>
    dispatch(setUploadPrescriptionOpenClosed());

  const uploadReportBtn = () => {
    return (
      <Grid item className={style.uploadReportBtnContainer}>
        <Button
          variant={IButtonVariant.WHITE}
          btnTrigger={openClosedUploadedPrescriptionModal}
        >
          <GetImages name="UploadTestIcon" width="26" height="20" />
          <FormattedMessage
            defaultMessage={"Upload Reports"}
            id="upload_reports"
          />
        </Button>
      </Grid>
    );
  };

  return (
    <>
      <Grid item className={style.consumerNameOrderDateContainer}>
        <Typography variant="h6">{order?.name}</Typography>
        <Typography component={"p"}>
          {order?.created_at
            ? transformDateToMMDDYYY(order?.created_at)
            : convertTimestampToDDMMM(order?.booking_timestamp, "DD MMM YYYY")}
        </Typography>
      </Grid>
      <Typography
        variant="subtitle1"
        color={"secondary"}
        className={style.orderType}
        gutterBottom
      >
        {capitalizeFirstLetter(order?.type ?? "")}
      </Typography>
      <Typography
        component={"p"}
        color={"secondary.light"}
        gutterBottom
        className={style.address}
      >
        {order?.address}
      </Typography>
      {order?.age && order?.gender && (
        <Typography
          component={"p"}
          color={"secondary.light"}
          gutterBottom
          className={style.ageGender}
        >
          Age {order?.age}, {order?.gender}
        </Typography>
      )}
      <Typography
        component={"p"}
        color={"secondary.light"}
        gutterBottom
        className={style.orderId}
      >
        Order Id : {order?.order_no}
      </Typography>
      {roleType === IRoleType.PHARMACY && (
        <Grid
          item
          className={style.pdfContainer}
          onClick={viewPrescriptionHandler}
        >
          <GetImages name="PDFGreyIcon" width="24" height="24" />
          <Typography component={"p"}>
            {getMedicalReportDocumentName(order?.pdfurl!)}
          </Typography>
        </Grid>
      )}
      {roleType === IRoleType.DIAGNOSTIC_CENTER && (
        <Grid item className={style.paymentContainer}>
          <PaymentDetail />
          {order?.reports && order?.reports?.length > 0 && <Reports />}
        </Grid>
      )}
      {roleType === IRoleType.PHARMACY &&
        order?.status === IOrderStatusEnum.COMPLETED && (
          <>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={1}
            >
              <Typography variant="subtitle1" className={style.deliveryPartner}>
                {order?.delivery_partner ?? (
                  <FormattedMessage defaultMessage={"Pickup"} id="pickup" />
                )}{" "}
              </Typography>
              <Typography component={"p"} className={style.deliveryDate}>
                {transformDateToMMDDYYY(order?.created_at)}
              </Typography>
            </Stack>
            {order?.tracking_id && (
              <Typography
                component={"p"}
                color={"secondary.light"}
                gutterBottom
                className={style.orderId}
              >
                ID : {order?.tracking_id}
              </Typography>
            )}
            <Typography
              component={"p"}
              color={"secondary.light"}
              gutterBottom
              className={style.amount}
            >
              Rs {order?.amount}
            </Typography>
          </>
        )}
      <Stack direction={"column"} gap={2} mt={4}>
        {order?.status === IOrderStatusEnum.COMPLETED && (
          <Grid item className={style.orderCompleted}>
            <Button variant={IButtonVariant.WHITE}>
              <GetImages name="TickOrangeIcon" width="26" height="20" />
              <FormattedMessage
                defaultMessage={"Order Completed"}
                id="order_complete"
              />{" "}
            </Button>
          </Grid>
        )}
        {order?.status === IOrderStatusEnum.PENDING && (
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={3}
          >
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={() => {
                openCloseConfirmModal();
                // onUpdate(IOrderStatusEnum.REJECTED);
                // dispatch(setIsPendingOrderRequestsOpen());
              }}
            >
              <FormattedMessage defaultMessage={"Reject"} id="reject" />
            </Button>
            <Button
              variant={IButtonVariant.ORANGE}
              btnTrigger={() => {
                onUpdate(IOrderStatusEnum.CONFIRMED);
                // dispatch(setIsPendingOrderRequestsOpen());
              }}
            >
              <FormattedMessage defaultMessage={"Accept"} id="accept" />
            </Button>
          </Stack>
        )}
        {order?.status === IOrderStatusEnum.REJECTED && (
          <Grid item className={style.orderRejected}>
            <GetImages
              name={
                roleType === IRoleType.DIAGNOSTIC_CENTER
                  ? "DiagnosticOrderRejectIcon"
                  : "OrderRejectIcon"
              }
              width="26"
              height="20"
            />
            <Typography component="p" color={"primary"} textAlign={"center"}>
              <FormattedMessage
                defaultMessage={"Order Rejected"}
                id="order_rejected"
              />{" "}
            </Typography>
          </Grid>
        )}
        {(order?.status === IOrderStatusEnum.CONFIRMED ||
          order?.status === IOrderStatusEnum.UPLOADED) && (
          <Grid item className={style.orderConfirmed}>
            {roleType === IRoleType.DIAGNOSTIC_CENTER && uploadReportBtn()}
            <Button
              variant={
                roleType === IRoleType.DIAGNOSTIC_CENTER
                  ? IButtonVariant.ORANGE
                  : IButtonVariant.WHITE
              }
              btnTrigger={() => onComplete()}
            >
              <FormattedMessage
                defaultMessage={"Mark Order Complete"}
                id="mark_order_complete"
              />
            </Button>
          </Grid>
        )}
        {roleType === IRoleType.PHARMACY && (
          <Button variant={IButtonVariant.ORANGE}>
            <GetImages name="CallWhiteIcon" width="25" height="20" />
            <FormattedMessage defaultMessage={"Call"} id="call" />
          </Button>
        )}
        {roleType === IRoleType.DIAGNOSTIC_CENTER && (
          <Grid item className={style.testUploadReportContainer}>
            {order?.status === IOrderStatusEnum.COMPLETED && uploadReportBtn()}
            <Button variant={IButtonVariant.WHITE}>
              <GetImages name="CallOrangeIconHover" width="25" height="20" />
              <FormattedMessage defaultMessage={"Call"} id="call" />
            </Button>
          </Grid>
        )}
      </Stack>
      {isConfirm && (
        <Confirmation
          isOpen={isConfirm}
          closeHandler={openCloseConfirmModal}
          title="reject_order"
          alterMessage="reject_order_msg"
          actionHandler={() => onUpdate(IOrderStatusEnum.REJECTED)}
        />
      )}
      <CreateMedicalReport
        isOpen={isUploadPrescriptionOpen}
        closeHandler={openClosedUploadedPrescriptionModal}
        memberId={order?.member_id!}
        showOnlyPrescription={true}
      />{" "}
    </>
  );
};

export default OrderDetails;
