export const style = {
  navigationContainer: {
    position: "fixed",
    zIndex: 1000,
    top: 0,
    width: "100%",
    left: 0,
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
      lg: "flex",
    },
    justifyContent: "space-between",
    alignItems: "center",
    padding: {
      md: "1.6rem 4.8rem",
      lg: "1.6rem 4.8rem",
      sm: "1.6rem 4.8rem",
    },
    background: "var(--neutral-100)",
  },
  navigation: { background: "transparent" },
  navigationItemContainer: {
    marginRight: "2rem",
    padding: 0,
    color: "var(--gray-400)",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    minWidth: "max-content",
    whiteSpace: "nowrap",
    fontSize: "1.6rem",
    fontWeight: "500",
    "& .MuiBottomNavigationAction-label": {
      marginLeft: "0.8rem",
    },
    "&.Mui-selected": {
      fontSize: "1.6rem",
      color: "var(--orange-500)",
    },
  },
  searchAppointmentInputContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& > input": {
      padding: "1.2rem",
      minWidth: {
        md: "40rem",
        lg: "60rem",
        xl: "60rem",
      },
      border: "none",
      outline: "none",
      borderRadius: "2rem",
      background: "var(--gray-50)",
    },
    "& > svg": {
      position: "absolute",
      right: "1.2rem",
    },
  },
  btnContainer: {
    "& > button": {
      padding: "0.6rem 2.1rem",
    },
  },
  dropdownText: {
    color: "var(--gray-700)",
    fontSize: "1.4rem",
    fontWeight: 500,
  },
  doctorImageContainer: {
    marginLeft: "2.4rem",
    "& > img": {
      borderRadius: "50%",
      border: "0.2rem solid var(--orange-500)",
    },
  },
  addPatientDoctorImageContainer: {
    display: "flex",
    alignItems: "center",
  },
  requestPharmacyOrders: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& > p": {
      color: "var(--orange-500)",
      fontSize: "1.4rem",
      fontWeight: 500,
    },
  },
  menuContainer: {
    marginTop: "1.2rem",
    "& .MuiPaper-root": {
      borderRadius: "0.8rem",
    },
    "& .MuiList-root": { padding: 0 },
  },
  menuItemContainer: {
    padding: "1.2rem 0.8rem",
    margin: "0rem",
    borderBottom: "0.1rem solid var(--gray-20)",
    "& .MuiListItemIcon-root": { minWidth: "min-content" },
  },
  makeAppointmentBtn: {
    "& > button": {
      width: "16.2rem",
    },
  },
  request: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      color: "var(--orange-500)",
      fontSize: "1.4rem",
      fontWeight: 400,
    },
  },
};
