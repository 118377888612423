import React, { useCallback, useState } from "react";
import {
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import GetImages from "../../../../GetImages";
import Button from "../../../../Button";
import MobileOTPVerify from "../../../../../views/Onboarding/MobileOTPVerify";
import UniversalSearch from "../../../../../views/Common/UniversalSearch";
import Confirmation from "../../../../Confirmation";

import { style } from "./style";

import PatientImage from "../../../../../assets/patientProfile.png";

import { storage } from "../../../../../utils/Storage";
import {
  topBarDropdownDispatchingEnums,
  languagesList,
} from "../../../../../utils";
import {
  patientDropdownNavItem,
  patientTopNavItems,
} from "../../../../../utils/common";
import useLogout from "../../../../../utils/hooks/useLogout";

import {
  IAddPatient,
  IModalShowHide,
  IPatientExistOrNotModule,
  IProfileDropdownEnums,
} from "../../../../../models/doctor";
import { IButtonVariant } from "../../../../../models/button";
import { NavDropdownType } from "../../../../../models";

import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  setAddPatient,
  setTopBarDropdownItems,
} from "../../../../../store/slices/doctor/topNavigation";
import { setModuleName } from "../../../../../store/slices/doctor/checkMobilePatientExistOrNot";
import { setIsShowUniversalSearch } from "../../../../../store/slices/config";

const PatientTopNav: React.FC = () => {
  const token = storage.getSessionToken("token");
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    (localStorage.getItem("lang") as string) || languagesList[0].value
  );
  const [showProfile, setShowProfile] = useState(null);

  const { topBarItems } = useAppSelector((state) => state.topNavigation);
  const { logout } = useLogout();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { addPatient } = useAppSelector((state) => state.topNavigation);
  const { moduleName } = useAppSelector((state) => state.patientExistOrNot);
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);
  const { pathname } = useLocation();

  const handleSelected = (value: string) => {
    setSelectedLanguage(value);
    storage.setSessionToken("lang", value);
    handleClose();
    window.location.reload();
  };

  const selectedLangLabel = languagesList
    ?.filter((data) => data.value === selectedLanguage)
    .map((lang) => lang.label);

  const handleShowHideDropDown = (type: IModalShowHide, event?: any) => {
    setShowProfile(type === IModalShowHide.HIDE ? null : event.currentTarget);
  };

  const openCloseProfileDropdown = useCallback(
    (identifier: IProfileDropdownEnums) => {
      setShowProfile(null);
      switch (identifier) {
        case IProfileDropdownEnums.MEMBER_PROFILE:
          navigate("/medical-records");
          return;
        case IProfileDropdownEnums.YOUR_ORDERS:
          navigate("/order-list");
          return;
        default:
          dispatch(
            setTopBarDropdownItems(topBarDropdownDispatchingEnums[identifier])
          );
          return;
      }
    },
    []
  );

  return (
    <Grid item sx={style.container}>
      <Grid item>
        <GetImages name="GBSCLogo" width="84" height="51" />
      </Grid>
      <Grid item sx={style.searchInputLocationContainer}>
        <Grid item sx={style.locationNameInputContainer}>
          <GetImages name="LocationAccessSmallIcon" width="24" height="20" />
          <Typography component={"p"}>Durgapur</Typography>
        </Grid>
        <input
          placeholder={intl.formatMessage({
            id: "topBarSearchPlaceholder",
            defaultMessage: "Search doctors, clinics, tests here",
          })}
          onClick={() => dispatch(setIsShowUniversalSearch(true))}
        />
        <Grid item sx={style.searchIcon}>
          <GetImages name="NavSearchIcon" width="24" height="24" />
        </Grid>
      </Grid>
      <Grid item sx={style.navItemsContainer}>
        {patientTopNavItems.map((nav, index) => (
          <Grid
            item
            key={index}
            sx={{
              ...style.navItemLabel,
              ...(pathname === nav.path && style.activeNavItem),
            }}
            onClick={() => navigate(nav.path)}
          >
            <Typography component={"p"}>
              <FormattedMessage id={nav.label} defaultMessage="Nav Item" />
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item sx={style.languageButton}>
        <Button
          variant={IButtonVariant.WHITE}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          btnTrigger={handleClick}
        >
          <GetImages name="WorldIcon" width="20" height="20" />
          <Typography component={"p"}>
            {selectedLangLabel[0]?.slice(0, 3)}
          </Typography>
          <GetImages name="downArrowRedIcon" width="16" height="10" />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {languagesList.length > 0 &&
            languagesList.map((data) => (
              <MenuItem
                key={data.value}
                selected={data.value === selectedLanguage}
                onClick={() => handleSelected(data.value)}
              >
                {data.label}
              </MenuItem>
            ))}
        </Menu>
      </Grid>
      <Grid item sx={style.telephoneEmergencyContainer}>
        <Grid item sx={style.telephoneContainer}>
          <GetImages name="phone20by20red" width="21" height="21" />
          <Typography component={"p"}>9721 108 108</Typography>
        </Grid>
        <Grid item sx={style.helplineTime}>
          <Typography component={"p"}>24/7 helpline</Typography>
        </Grid>
      </Grid>
      <Grid item sx={style.loginButton}>
        {token ? (
          <>
            <Box sx={style.profileImage}>
              <img
                src={PatientImage}
                alt="profile"
                onClick={(event) =>
                  handleShowHideDropDown(IModalShowHide.SHOW, event)
                }
              />
            </Box>
            <Menu
              anchorEl={showProfile}
              open={!!showProfile}
              onClose={() =>
                handleShowHideDropDown(
                  IModalShowHide.HIDE,
                  NavDropdownType.PROFILE
                )
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={style.menuContainer}
            >
              {patientDropdownNavItem?.map((profile: any, index: number) => (
                <MenuItem
                  sx={style.menuItemContainer}
                  onClick={() => openCloseProfileDropdown(profile.identifier)}
                  key={index}
                >
                  <ListItemIcon>
                    <GetImages name={profile.iconName} width="24" height="20" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography component={"p"} sx={style.dropdownText}>
                        <FormattedMessage
                          id={profile.text}
                          defaultMessage="Drop Down Item"
                        />
                      </Typography>
                    }
                  />
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Button
            btnTrigger={() => {
              dispatch(setModuleName(IPatientExistOrNotModule.LOGIN));
              dispatch(
                setAddPatient({
                  field: IAddPatient.MOBILE_CHECK,
                  value: true,
                })
              );
            }}
          >
            <FormattedMessage id="login" defaultMessage="Login" />
          </Button>
        )}
      </Grid>
      {moduleName === IPatientExistOrNotModule.LOGIN &&
        addPatient?.mobileCheck && <MobileOTPVerify />}
      {isShowUniversalSearch && <UniversalSearch />}

      <Confirmation
        isOpen={topBarItems?.isLogoutOpen}
        closeHandler={() =>
          openCloseProfileDropdown(IProfileDropdownEnums.LOGOUT)
        }
        title="logout"
        alterMessage={"logout_alert_message"}
        actionHandler={logout}
      />
    </Grid>
  );
};

export default PatientTopNav;
