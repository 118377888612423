import React from "react";
import dayjs from "dayjs";

import { handleUpdateOrderApi } from "../apis/pharmacy";

import {
  IOrderStatusEnum,
  IPharmacyDiagnosticOrderFilterEnum,
  IPharmacyOrderDetailUpdate,
} from "../../models/ApiRequestResponse/pharmacy";
import { IPharmacyDiagnosticOrderDetail } from "../../models/ApiRequestResponse/diagnostic";
import { IRoleType } from "../../models/role";
import { IOrderDateRangeParams } from "../../models/doctor";

import {
  fetchDiagnosticTestOrdersList,
  fetchMoreDiagnosticTestOrdersList,
  fetchMoreOrders,
  fetchPharmacyOrdersList,
  updatePendingOrderList,
} from "../../store/slices/pharmacyClinicDiagnostic";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { INotifyEnum, notify } from "../toaster";

import { storage } from "../Storage";

import usePagination from "./usePagination";

import { updateDiagnosticOrderApi } from "../apis/diagnostic";

const useHandlePharmacyOrderUpdate = () => {
  const dispatch = useAppDispatch();

  const roleType = storage.getSessionToken("roleType");

  const isDiagnosticCenter = roleType === IRoleType.DIAGNOSTIC_CENTER;

  const { handleFetch } = usePagination<IOrderDateRangeParams>({
    fetchFn: isDiagnosticCenter
      ? fetchDiagnosticTestOrdersList
      : fetchPharmacyOrdersList,
    fetchNextFn: isDiagnosticCenter
      ? fetchMoreDiagnosticTestOrdersList
      : fetchMoreOrders,
  });

  const { pendingOrdersList, order } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );

  const handlePharmacyOrderUpdate = async (
    status: IOrderStatusEnum,
    values?: Partial<IPharmacyOrderDetailUpdate> | null,
    medicalRecordIds?: string[]
  ) => {
    try {
      let response;
      if (isDiagnosticCenter) {
        response = await updateDiagnosticOrderApi({
          ...(status ? { status } : {}),
          id: order?.id,
          ...(values?.discount && { discountAmount: values?.discount }),
          ...(medicalRecordIds ? { medicalRecordIds } : {}),
        });
      } else {
        response = await handleUpdateOrderApi({
          ...(values &&
          order.type === IPharmacyDiagnosticOrderFilterEnum.HOME_DELIVERY
            ? {
                amount: values?.totalBill,
                tracking_id: values?.trackingId,
                delivery_partner: values?.deliveryPartner,
              }
            : {
                amount: values?.totalBill,
              }),
          orderId: order?.id,
          status,
        });
      }

      if (response.statusCode === 200) {
        const { id } = order as IPharmacyDiagnosticOrderDetail;
        if (
          status === IOrderStatusEnum.COMPLETED ||
          status === IOrderStatusEnum.UPLOADED ||
          status === IOrderStatusEnum.CANCELLED
        ) {
          handleFetch({
            isPending: false,
            ...(isDiagnosticCenter
              ? {
                  startDate: dayjs().startOf("day").toDate().toISOString(),
                  endDate: dayjs().endOf("day").toDate().toISOString(),
                }
              : {
                  startTime: dayjs().startOf("day").toISOString(),
                  endTime: dayjs().endOf("day").toISOString(),
                }),
          });
          notify(INotifyEnum.SUCCESS, response.message);
          return;
        } else {
          const newPendingOrderList = pendingOrdersList.filter(
            (order) => order?.id !== id
          );
          dispatch(updatePendingOrderList([...newPendingOrderList]));
          handleFetch({
            isPending: false,
            ...(isDiagnosticCenter
              ? {
                  startDate: dayjs().startOf("day").toDate().toISOString(),
                  endDate: dayjs().endOf("day").toDate().toISOString(),
                }
              : {
                  startTime: dayjs().startOf("day").toISOString(),
                  endTime: dayjs().endOf("day").toISOString(),
                }),
          });
        }
        notify(INotifyEnum.SUCCESS, response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { handlePharmacyOrderUpdate };
};

export default useHandlePharmacyOrderUpdate;
