import { IAPIStatusCode } from "../utils/apis/APIEndpointConfig";
import {
  IMedicine,
  INextVisitType,
  ITagOtherHealthProblemDiagnosis,
  ITest,
} from "./ApiRequestResponse/doctor";

export enum IDoctorAvailabilityPlatform {
  VIDEO_CALL = "VIDEO_CALL",
  IN_CLINIC = "IN_CLINIC",
  BOTH = "BOTH",
}

export enum IFindDoctorAvailabilityPlatform {
  VIDEO_CALL = "videoConsultation",
  IN_CLINIC = "inClinic",
  BOTH = "both",
}

export enum IMakeAppointmentEvent {
  VIDEO_CALL = 0,
  IN_CLINIC = 1,
}

export enum IDoctorScheduleConsultationType {
  IN_CLINIC = "inClinic",
  VIDEO_CALL = "videoConsultation",
}

export enum IDoctorScheduleOrderType {
  IN_CLINIC = "inClinic",
  VIDEO_CALL = "videoConsultation",
  MEDICINE = "medicine",
  TEST = "test",
}

export enum IDoctorProfileStatus {
  VERIFIED,
  ASK_FREE,
  NEW,
}
export enum IAppointmetEnums {
  CREATION = "creation",
  MOBILE_CHECK = "mobileCheck",
  PATIENT_DETAIL = "patientDetail",
  SUCCESS = "success",
  OTP = "otp",
}

export interface IAppointmentCreation {
  creation: boolean;
  mobileCheck?: boolean;
  patientDetail?: boolean;
  checkout?: boolean;
  otp?: boolean;
  success: boolean;
  patientAlreadyExist?: boolean;
}
export enum IDoctorConsultationSelection {
  IN_CLINIC = "In Clinic",
  VIDEO_CONSULTATION = "Video Consultation",
}

export enum IDoctorConsultationType {
  IN_CLINIC = "1",
  VIDEO_CONSULTATION = "2",
}
export enum IDropDownSelectionType {
  CONSULTATION = "CONSULTATION",
  CLINIC_NAME = "CLINIC_NAME",
  SLOT_DURATION = "SLOT_DURATION",
  BUFFER_TIME = "BUFFER_TIME",
  GENDER = "GENDER",
  CHANGE_NAME = "CHANGE_NAME",
  SPECIALITY = "speciality",
  TYPE_OF_CONSULTATION = "TypeOfConsultation",
  EXPERINCE = "yearOfExperience",
  DISTANCE = "distance",
  LANGUAGES = "language",
  HOSPITAL_CLINICS = "HOSPITAL_CLINICS",
  SPONSORS = "SPONSORS",
  ICU = "ICU",
  WARD = "WARD",
  RATING = "RATING",
}

export enum ITimePickerType {
  START_TIME = "START_TIME",
  END_TIME = "END_TIME",
}

export enum IPatientAppointmentStatus {
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
}

export enum IDays {
  SUN = "Sun",
  MON = "Mon",
  TUE = "Tue",
  WED = "Wed",
  THU = "Thu",
  FRI = "Fri",
  SAT = "Sat",
}

export interface IGender {
  isOpen: boolean;
  value: string;
}

export interface IMakeAppointmentFromDoctorSlice {
  type: IDoctorAvailabilityPlatform;
  dateSlotTime: string;
  slotId: string;
  clinicId?: string;
  clinicName: string;
  memberId: string;
  fee: number;
  name: string;
  age: string;
  gender: string;
  mobileNo: string;
}

export interface ICheckNumberErrors {
  message: string;
  statusCode: IAPIStatusCode | string;
}

export enum IProfileDropdownEnums {
  PROFILE = "PROFILE",
  REVIEW = "REVIEW",
  AVAILABILITY_SETTINGS = "AVAILABILITY_SETTINGS",
  CLINICAL_TEAM = "CLINICAL_TEAM",
  TEMPLATES = "TEMPLATES",
  CHANGE_HOSPITAL = "CHANGE_HOSPITAL",
  LOGOUT = "LOGOUT",
  NOTIFICATION = "NOTIFICATION",
  HELP_SUPPORT = "HELP_SUPPORT",
  MEMBER_PROFILE = "MEMBER_PROFILE",
  YOUR_ORDERS = "YOUR_ORDERS",
  CHAT = "CHAT",
}

export enum IPrescriptionFilterType {
  PRESCRIPTION = "prescription",
}

export enum IVitalsIdentifiers {
  BP = "bp",
  PULSE = "pulse",
  TEMP = "temperature",
  WEIGHT = "weight",
  HEIGHT = "height",
  BMI = "bmi",
}

export enum ITitleInputValuesListEnums {
  TAG = "tag",
  OTHER_HEALTH_PROBLEMS = "otherHealthProblem",
  COMPLAINT = "complaint",
  DIAGNOSIS = "diagnosis",
  MEDICINE = "medicine",
  TEST = "test",
  DIET_AND_EXERCISE = "dietAndExercise",
  NOTE = "note",
}

export enum IAccordionInputChipListEnums {
  NEXT_VISIT = "NEXT_VISIT",
  HEALTH_CATEGORIES = "HEALTH_CATEGORIES",
}
export interface ICreatePrescription {
  id?: string;
  doctorId?: string;
  patientId?: string;
  memberId?: string;
  bookingId?: string;
  prescription_id?: string;
  bp: string;
  pulse: number;
  temperature: number;
  weight: number;
  height: number;
  bmi: number;
  tag: ITagOtherHealthProblemDiagnosis[];
  otherHealthProblem: ITagOtherHealthProblemDiagnosis[];
  complaint: string[];
  diagnosis: ITagOtherHealthProblemDiagnosis[];
  dietAndExercise: string;
  note: string;
  test: ITest[];
  medicine: IMedicine[];
  healthCategoryId: string;
  nextVisitType: INextVisitType;
  nextVisitNo: number;
}

export enum ISaveTypePrescription {
  SAVE_PRESCRIPTION = "1",
  END_CONSULTATION = "2",
}

export interface IMedicineTestModalShow {
  medicine: boolean;
  test: boolean;
}

export enum IMedicalRecordTypeEnum {
  All = "ALL",
  PRESCRIPTION = "PRESCRIPTION",
  REPORT = "REPORT",
  SUPPORT_ATTACHMENT = "SUPPORT_ATTACHMENT",
  HEALTHCATEGORY = "HEALTH_CATEGORY",
}

export enum ISharePrescriptionIcons {
  WHATSAPP_ICON = "WhatsAppIcon",
  GMAIL_ICON = "GmailIcon",
  PRINT_ICON = "PrintIcon",
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export enum IPrescriptionAttributeType {
  Tag = 1,
  HealthProblem = 2,
  Diagnosis = 3,
}

export interface IScheduleAppointmentDateRangeParams {
  start?: string;
  end?: string;
}

export interface IOrderDateRangeParams {
  limit?: number;
  startTime?: string;
  endTime?: string;
  startDate?: string;
  endDate?: string;
  type?: string | null;
  status?: string | null;
  keyword?: string;
  isPending?: boolean;
  offset?: number;
}

export enum ITemplateCreateEnum {
  NAME = "name",
  DIAGNOSIS = "diagnosis",
  MEDICINE = "medicine",
  TEST = "test",
  DIET_AND_EXERCISE = "dietAndExercise",
}
export interface IMedicineTestSuggestionData {
  testId: string;
  name: string;
  medicineId: string;
}

export enum IMealDoseTimeTypesEnums {
  MEAL_TIME = "MEAL_TIME",
  DOSE_TIME = "DOSE_TIME",
}

export enum IRoomType {
  ICU = "ICU",
  WARD = "WARD",
}

export enum IPDashboardFilterType {
  ACTIVE = "ACTIVE",
  DISCHARGE = "DISCHARGE",
  WARD = "WARD",
  ICU = "ICU",
}

export interface IpDashboardFilterModal {
  [IPDashboardFilterType.ICU]: boolean;
  [IPDashboardFilterType.WARD]: boolean;
}
export interface ISelectedDate {
  startDate: string;
  endDate: string;
}

export enum IPDashboardPatientAdmitStatus {
  ADMIT = "ADMIT",
  DISCHARGED = "DISCHARGED",
}

export interface IAdmitPatientState {
  hospital_id: string;
  hospital_room_id: string;
  room_bed_id: string;
  sponsor_name: string;
  case_history: string;
  admission_date: string;
  hospital_uhid: string;
  name: string;
  age: string;
  gender: string;
  mobile_number: string;
}

export enum IAdmitPatientType {
  SET_FIELD = "SET_FIELD",
  SET_API_DATA = "SET_API_DATA",
}

export enum IAdmitPatientModalTypes {
  GENDER = "gender",
  SPONSOR = "sponsor",
  MEMBER = "member",
}

export interface IKeyPairsValues {
  [key: string]: string;
}

export enum IPatientExistOrNotModule {
  APPOINTMENT = "APPOINTMENT",
  ADMIT_PATIENT = "ADMIT_PATIENT",
  LOGIN = "LOGIN",
}

export enum IAddPatient {
  APPOINTMENT = "appointmentCreateOpen",
  MOBILE_CHECK = "mobileCheck",
  OTP_VERIFY = "otpVerify",
  ADMIT_PATIENT = "admiPatient",
}

export enum IChangeHospitalModuleName {
  FROM_LIST = "FROM_LIST",
  FROM_ADMIT_PATIENT = "FROM_ADMIT_PATIENT",
}

export enum IShiftBedDischaggePatientModal {
  SHIFTBED = "shiftBed",
  DISCHARGE = "discharge",
}
export enum IModalShowHide {
  SHOW = "SHOW",
  HIDE = "HIDE",
}

export enum IPatientDetailEnumWeb {
  CANCEL = "CANCEL",
  RESCHEDULE = "RESCHEDULE",
  CHAT = "CHAT",
  RECORD = "RECORD",
  PRESCRIPTION = "PRESCRIPTION",
  VIDEO_JOIN = "VIDEO_JOIN",
  IP_DETAIL = "IP_DETAIL",
  CLINICAL_TEAM = "CLINICAL_TEAM",
}

export enum IWebPanelType {
  SCHEDULE = "SCHEDULE",
  IP_DASHBOARD = "IP_DASHBOARD",
  CLINIC = "CLINIC",
}

export interface IWebPanelTabButton {
  identifier: IPatientDetailEnumWeb;
  label: string;
  icon: string;
  labelStyle: string;
  backStyle: string;
}

export interface IAdmitPatientPopUps {
  isAdmitted: boolean;
  isDischarged: boolean;
}

export enum IAdmitPatientPopUpsEnums {
  ADMITTED = "isAdmitted",
  DISCHARGED = "isDischarged",
}

export enum IScheduleAppointmentFilterEnum {
  ALL = "All",
  UPCOMING = "Upcoming",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
}
export enum IUniversalSearchEnum {
  // Doctor role
  APPOINTMENTS = "APPOINTMENTS",
  IN_PATIENTS = "IN_PATIENTS",
  CLINICAL_TEAM = "CLINICAL_TEAM",
  // Patient role
  FIND_DOCTORS = "FIND_DOCTORS",
  ORDERS = "ORDERS",
  DIAGNOSTIC = "DIAGNOSTIC",
  TEST = "TEST",
  HEALTH_HUB = "HEALTH_HUB",
  // pharmacy & in patient appointments
  PHARMACY_ORDER = "PHARMACY_ORDER",
  CLINIC_APPOINTMENTS = "CLINIC_APPOINTMENTS",
  // Diagnostic role
  DIAGNOSTIC_CENTER = "DIAGNOSTIC_CENTER",
}

export interface IUniversalSearchResultCounts {
  [IUniversalSearchEnum.APPOINTMENTS]: number | undefined;
  [IUniversalSearchEnum.PHARMACY_ORDER]: number | undefined;
  [IUniversalSearchEnum.FIND_DOCTORS]: number | undefined;
  [IUniversalSearchEnum.TEST]: number | undefined;
  [IUniversalSearchEnum.DIAGNOSTIC]: number | undefined;
  [IUniversalSearchEnum.HEALTH_HUB]: number;
  [IUniversalSearchEnum.CLINICAL_TEAM]: number;
  [IUniversalSearchEnum.CLINIC_APPOINTMENTS]: number | undefined;
  [IUniversalSearchEnum.ORDERS]: number | undefined;
  [IUniversalSearchEnum.IN_PATIENTS]: number | undefined;
  [IUniversalSearchEnum.DIAGNOSTIC_CENTER]: number | undefined;
}

export enum IOrderStatusEnum {
  ACCEPTED = "Accepted",
  COMPLETED = "Completed",
  REJECTED = "Rejected",
  PENDING = "Pending",
  CONFIRMED = "Confirmed",
}

export interface IScheduleSlotsRequest {
  doctorId?: string | null;
  clinicId?: string | null;
}

export interface IDialogConfig {
  component: React.ReactNode;
  size: "sm" | "md" | "lg";
}

export interface IDialogMappings {
  isProfileOpen?: IDialogConfig;
  isReviewOpen?: IDialogConfig;
  isTemplatesOpen?: IDialogConfig;
  isAvailabilityOpen?: IDialogConfig;
}

export enum IAppointmentCardDetailEnum {
  SEARCH = "SEARCH",
  LIST = "LIST",
}

export enum ICreateMedicalReportEnum {
  PRESCRIPTION = "PRESCRIPTION",
  MEDICINE = "MEDICINE",
}
