import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchDoctorHospitalPatientListing,
  setSelectedDate,
} from "../../store/slices/doctor/ipDashboard";

const useIPDashboardDateFilter = () => {
  const { selectedHospitalId } = useAppSelector((state) => state.ipDashboard);

  const dispatch = useAppDispatch();

  const applyDateRangeValues = (startDate: string, endDate: string) => {
    const filterOptions = {
      start_date: startDate,
      end_date: endDate,
    };
    dispatch(
      setSelectedDate({
        startDate: startDate,
        endDate: endDate,
      })
    );
    dispatch(
      fetchDoctorHospitalPatientListing({
        hospitalId: selectedHospitalId,
        ...filterOptions,
      })
    );
  };
  return { applyDateRangeValues };
};

export default useIPDashboardDateFilter;
