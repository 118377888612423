import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import GenericCardHeader from "../../GenericCardHeader";
import GetImages from "../../GetImages";
import Button from "../../Button";
import Confirmation from "../../Confirmation";

import style from "./index.module.css";

import {
  ICompleteOrPendingMedicineOrder,
  IMedicineOrderType,
  IOrderStatus,
} from "../../../models/ApiRequestResponse/patient";
import { IButtonVariant } from "../../../models/button";
import { IOrderStatusEnum } from "../../../models/ApiRequestResponse/pharmacy";

import { convertDDMMYYYYHHMM, openClinicMapLocation } from "../../../utils";
import { handleUpdateOrderApi } from "../../../utils/apis/pharmacy";
import { INotifyEnum, notify } from "../../../utils/toaster";
import { storage } from "../../../utils/Storage";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  allOrders,
  fetchHomeModules,
  setAllOrderStatusUpdate,
} from "../../../store/slices/patient/home";

const MedicineCard: React.FC<ICompleteOrPendingMedicineOrder> = (props) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const {
    id,
    name,
    type,
    status,
    createdat,
    address,
    city,
    latitude,
    longitude,
    tracking_id,
    delivery_partner,
  } = props;

  const intl = useIntl();

  const userId = storage.getSessionToken("user_id");

  const allOrdersList = useAppSelector(allOrders);
  const dispatch = useAppDispatch();

  const callOrGetDirectionHandler = (): void => {
    if (type === IMedicineOrderType.PICKUP) {
      openClinicMapLocation(latitude ?? 0, longitude ?? 0);
    }
  };

  const cancelOrderModalHandler = (): void =>
    setIsCancelModalOpen(!isCancelModalOpen);

  const medicineOrderCancelHandler: () => Promise<void> = async () => {
    const cancelOrderResponse = await handleUpdateOrderApi({
      orderId: id,
      status: IOrderStatusEnum.CANCELLED,
    });
    if ("result" in cancelOrderResponse) {
      notify(INotifyEnum.SUCCESS, cancelOrderResponse?.message);
      cancelOrderModalHandler();
      if (allOrdersList?.length === 0) {
        dispatch(fetchHomeModules({ userId: userId ?? "" }));
      } else {
        dispatch(
          setAllOrderStatusUpdate({
            ...props,
            status: IOrderStatusEnum.CANCELLED,
          })
        );
      }
    } else {
      notify(INotifyEnum.ERROR, cancelOrderResponse?.message);
    }
  };

  return (
    <>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        gap={0.5}
        className={style.cardContainer}
      >
        <GenericCardHeader
          orderStatus={status}
          doctorId={""}
          name={name}
          bio={
            type === IMedicineOrderType.HOME_DELIVERY
              ? intl.formatMessage({
                  id: "home_delivery",
                  defaultMessage: "Home Delivery",
                })
              : intl.formatMessage({
                  id: "self_pickup",
                  defaultMessage: "Self Pickup",
                })
          }
          imageSvg={
            <Grid item className={style.headerIcon}>
              <GetImages name="MedicineBottleIcon" height={"38"} width={"26"} />
            </Grid>
          }
        />
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <GetImages
            name={
              type === IMedicineOrderType.HOME_DELIVERY
                ? "LocationSmallIcon"
                : "ClinicSmallIcon"
            }
            width="18"
            height="16"
          />
          <Typography color={"secondary"} variant="caption">
            {address}, {city}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <GetImages
            name={"ScheduleSelectedSmallIcon"}
            width="18"
            height="16"
          />
          <Typography variant={"caption"} color={"secondary"}>
            {type === IMedicineOrderType.HOME_DELIVERY
              ? intl.formatMessage({
                  id: "order_placed_at",
                  defaultMessage: "Order placed at",
                })
              : intl.formatMessage({
                  id: "collect_before",
                  defaultMessage: "Collect before",
                })}{" "}
            {convertDDMMYYYYHHMM(createdat)}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={1} width={"100%"}>
          {(status === IOrderStatus.PENDING ||
            status === IOrderStatus.COMPLETED) && (
            <>
              {tracking_id && (
                <>
                  <GetImages name={"LikeIcon"} width="18" height="16" />
                  <Typography component={"p"} className={style.footerText}>
                    {delivery_partner},
                    <Typography component={"span"}>
                      <FormattedMessage
                        id="tracking_id"
                        defaultMessage="Tracking ID"
                      />
                      - {tracking_id}
                    </Typography>
                  </Typography>
                </>
              )}
            </>
          )}
          {status === IOrderStatus.PENDING && (
            <>
              <Button
                variant={IButtonVariant.WHITE}
                btnTrigger={cancelOrderModalHandler}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button btnTrigger={callOrGetDirectionHandler}>
                <GetImages
                  name={
                    type === IMedicineOrderType.HOME_DELIVERY
                      ? "TelePhoneWhiteIcon"
                      : "LocationRedirectIcon"
                  }
                  width="26"
                  height="20"
                />
                {type === IMedicineOrderType.HOME_DELIVERY
                  ? "Call Store"
                  : "Get Directions"}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      <Confirmation
        isOpen={isCancelModalOpen}
        closeHandler={cancelOrderModalHandler}
        title={"cancel_order"}
        alterMessage="cancel_order_alert_message"
        actionHandler={medicineOrderCancelHandler}
      />
    </>
  );
};

export default MedicineCard;
