import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import ProfileStatusBadge from "../ProfileStatusBadge";
import Dialog from "../Dialog";
import DoctorProfile from "../../views/Common/DoctorProfile";

import { styles } from "../../utils/styles";

import { IDoctorProfileStatus, IKeyPairsValues } from "../../models/doctor";
import { IOrderStatus } from "../../models/ApiRequestResponse/patient";

import PersonImage from "../../assets/p1.png";

import { capitalizeFirstLetter, transformClasses } from "../../utils";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setIsShowUniversalSearch } from "../../store/slices/config";

interface IProps {
  doctorId?: string;
  image?: string;
  imageSvg?: React.ReactNode;
  orderStatus?: IOrderStatus;
  status?: IDoctorProfileStatus;
  name: string;
  bio: string;
  languages?: string;
  liveQueue?: number;
}

const orderStatusMap: IKeyPairsValues = {
  [IOrderStatus.COMPLETED]: transformClasses(style.statusCompleted),
  [IOrderStatus.CONFIRMED]: transformClasses(style.statusCompleted),
  [IOrderStatus.CANCELLED]: transformClasses(style.statusCancelled),
  [IOrderStatus.PENDING]: transformClasses(style.statusUpcoming),
  [IOrderStatus.UPCOMING]: transformClasses(style.statusUpcoming),
  [IOrderStatus.UPLOADED]: transformClasses(style.statusUpcoming),
};

const GenericCardHeader: React.FC<IProps> = (props) => {
  const {
    image,
    imageSvg,
    bio,
    name,
    languages,
    status,
    doctorId,
    orderStatus,
    liveQueue,
  } = props;

  const [isDoctorDetailShow, setIsDoctorDetailShow] = useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);
  const viewDoctorProfilePage = () => {
    if (!doctorId) return;
    navigate(`/doctor-profile`, { state: { doctorId } });
    if (isShowUniversalSearch) {
      dispatch(setIsShowUniversalSearch(false));
    }
  };

  return (
    <>
      <Grid
        item
        className={style.headerContainer}
        onClick={viewDoctorProfilePage}
      >
        <Grid item className={style.imageContainer}>
          {imageSvg ? (
            imageSvg
          ) : (
            <img
              src={image ?? PersonImage}
              alt="doctorPhoto"
              width={56}
              height={56}
            />
          )}
          {status ? (
            <Grid item className={style.profileStatus}>
              <ProfileStatusBadge status={status} />
            </Grid>
          ) : null}
        </Grid>
        <Grid item sx={styles.ml8}>
          <Typography
            component={"h6"}
            className={style.name}
            textAlign={"left"}
          >
            {name}
          </Typography>
          <Typography component={"p"} className={style.bio} textAlign={"left"}>
            {bio}
          </Typography>
          {languages && (
            <Typography
              component={"p"}
              className={style.languages}
              textAlign={"left"}
            >
              {languages}
            </Typography>
          )}
          {orderStatus && (
            <Typography
              component={"p"}
              className={transformClasses(
                style.status,
                orderStatusMap[orderStatus]
              )}
            >
              {(orderStatus === IOrderStatus.PENDING ||
                orderStatus === IOrderStatus.UPCOMING) &&
              liveQueue
                ? `Live Que: ${liveQueue} people before you`
                : orderStatus === IOrderStatus.PENDING ||
                  orderStatus === IOrderStatus.UPCOMING
                ? capitalizeFirstLetter(IOrderStatus.UPCOMING)
                : capitalizeFirstLetter(orderStatus)}
            </Typography>
          )}
        </Grid>
      </Grid>
      {isDoctorDetailShow && (
        <Dialog
          fullWidth
          maxWidth="sm"
          isOpen={isDoctorDetailShow}
          closeHandler={() => setIsDoctorDetailShow(!isDoctorDetailShow)}
        >
          <DoctorProfile doctorID={doctorId} />
        </Dialog>
      )}
    </>
  );
};

export default GenericCardHeader;
