import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IOrderDateRangeParams } from "../../../models/doctor";
import {
  IOrderStatusEnum as IPharmacyDiagnosticStatus,
  ISalesDetail,
} from "../../../models/ApiRequestResponse/pharmacy";
import {
  IDiagnosticPharmacyProfileDetail,
  IPharmacyDiagnosticOrderDetail,
} from "../../../models/ApiRequestResponse/diagnostic";

import {
  getPharmacyOrderListingApi,
  getPharmacyProfileApi,
  getPharmacyProfileMonthlyAnalysisApi,
} from "../../../utils/apis/pharmacy";
import { convertDateToTimeStamp, convertKeysToCamelCase } from "../../../utils";
import {
  getDiagnosticOrderListingApi,
  getDiagnosticProfileApi,
  getDiagnosticProfileMonthlyAnalysisApi,
} from "../../../utils/apis/diagnostic";

export const fetchPharmacyOrdersList = createAsyncThunk(
  "pharmacy-orders",
  async ({
    startTime,
    endTime,
    status,
    isPending,
    type,
    keyword,
    offset,
  }: IOrderDateRangeParams) => {
    const response = await getPharmacyOrderListingApi({
      startTime,
      endTime,
      status,
      isPending,
      type,
      keyword,
      offset,
    });
    return response;
  }
);

export const fetchPharmacySearchOrdersList = createAsyncThunk(
  "pharmacy-search-orders",
  async ({ keyword, offset }: IOrderDateRangeParams) => {
    const response = await getPharmacyOrderListingApi({
      keyword,
      offset,
    });
    return response;
  }
);

export const fetchPharmacyRequestOrdersList = createAsyncThunk(
  "pharmacy-orders-request",
  async ({ offset }: IOrderDateRangeParams) => {
    const response = await getPharmacyOrderListingApi({
      offset,
      status: IPharmacyDiagnosticStatus.PENDING,
    });
    return response;
  }
);

export const fetchMorePharmacyRequestOrdersList = createAsyncThunk(
  "fetch-more-pharmacy-orders-request",
  async ({ offset }: IOrderDateRangeParams) => {
    const response = await getPharmacyOrderListingApi({
      status: IPharmacyDiagnosticStatus.PENDING,
      offset,
    });
    return response;
  }
);

export const getPharmacySearchOrderList = createAsyncThunk(
  "pharmacy-order-search",
  async ({ keyword }: IOrderDateRangeParams) => {
    const response = await getPharmacyOrderListingApi({ keyword });
    return response;
  }
);

export const fetchPharmacyProfile = createAsyncThunk(
  "pharmacy-profile",
  async () => {
    const response = await getPharmacyProfileApi();
    return response;
  }
);

export const fetchDiagnosticTestOrdersList = createAsyncThunk(
  "diagnostic-orders",
  async ({
    startDate,
    endDate,
    status,
    isPending,
    type,
    keyword,
    offset,
  }: IOrderDateRangeParams) => {
    const response = await getDiagnosticOrderListingApi({
      startDate: startDate
        ? convertDateToTimeStamp(new Date(startDate as string))
        : undefined,
      endDate: endDate
        ? convertDateToTimeStamp(new Date(endDate as string))
        : undefined,
      status,
      isPending,
      type,
      keyword,
      offset,
    });
    return response;
  }
);

export const fetchSearchDiagnosticOrdersList = createAsyncThunk(
  "search-diagnostic-orders",
  async ({ keyword, offset }: IOrderDateRangeParams) => {
    const response = await getDiagnosticOrderListingApi({
      keyword,
      offset,
    });
    return response;
  }
);

export const fetchMoreDiagnosticTestOrdersList = createAsyncThunk(
  "fetch-next-diagnostic-orders",
  async ({
    startDate,
    endDate,
    status,
    isPending,
    type,
    keyword,
    offset,
  }: IOrderDateRangeParams) => {
    const response = await getDiagnosticOrderListingApi({
      startDate: startDate
        ? convertDateToTimeStamp(new Date(startDate))
        : undefined,
      endDate: endDate ? convertDateToTimeStamp(new Date(endDate)) : undefined,
      status,
      isPending,
      type,
      keyword,
      offset,
    });
    return response;
  }
);

export const fetchDiagnosticRequestOrdersList = createAsyncThunk(
  "diagnostic-orders-request",
  async ({ keyword }: { keyword?: string }) => {
    const response = await getDiagnosticOrderListingApi({
      status: IPharmacyDiagnosticStatus.PENDING,
      ...(keyword ? { keyword } : {}),
    });
    return response;
  }
);

export const fetchDiagnosticProfile = createAsyncThunk(
  "diagnostic-profile",
  async () => {
    const response = await getDiagnosticProfileApi();
    return response;
  }
);

export const fetchPharmacyProfileAnalytics = createAsyncThunk(
  "pharmacy-profile-analytics",
  async ({ startTime, endTime }: IOrderDateRangeParams) => {
    const response = await getPharmacyProfileMonthlyAnalysisApi({
      startTime,
      endTime,
    });
    return response;
  }
);

export const fetchDiagnosticProfileAnalytics = createAsyncThunk(
  "diagnostic-profile-analytics",
  async ({ startTime, endTime }: IOrderDateRangeParams) => {
    const response = await getDiagnosticProfileMonthlyAnalysisApi({
      startTime,
      endTime,
    });
    return response;
  }
);

export const fetchMoreOrders = createAsyncThunk(
  "fetch-next",
  async ({
    startTime,
    endTime,
    status,
    isPending,
    type,
    keyword,
    offset,
  }: IOrderDateRangeParams) => {
    const response = await getPharmacyOrderListingApi({
      startTime,
      endTime,
      status,
      isPending,
      type,
      keyword,
      offset,
    });
    return response;
  }
);

const pharmacyClinicDiagnostic = createSlice({
  name: "pharmacyClinicDiagnostic",
  initialState: {
    profile: {} as IDiagnosticPharmacyProfileDetail,
    sales: {} as ISalesDetail,
    isLoading: true as boolean,
    list: [] as IPharmacyDiagnosticOrderDetail[],
    searchOrders: [] as IPharmacyDiagnosticOrderDetail[],
    pendingOrdersList: [] as IPharmacyDiagnosticOrderDetail[],
    order: {} as IPharmacyDiagnosticOrderDetail,
    isPharmacyRequestOrderLoading: true as boolean,
    isPendingOrderRequestsOpen: false as boolean,
    isViewPrescriptionOpen: false as boolean,
    medicalRecordIds: [] as string[],
    orderRequest: {} as IPharmacyDiagnosticOrderDetail,
  },
  reducers: {
    saveSelectedOrderRequest: (state, action) => ({
      ...state,
      orderRequest: { ...action.payload },
    }),
    saveSelectedOrder: (state, action) => ({
      ...state,
      order: { ...action.payload },
    }),
    setIsPendingOrderRequestsOpen: (state) => {
      state.isPendingOrderRequestsOpen = !state.isPendingOrderRequestsOpen;
    },
    setIsViewPrescriptionOpen: (state) => {
      state.isViewPrescriptionOpen = !state.isViewPrescriptionOpen;
    },
    updateOrderList: (state, action) => {
      state.list = action.payload;
    },
    updateSearchOrderList: (state, action) => {
      state.searchOrders = action.payload;
    },
    updatePendingOrderList: (state, action) => {
      state.pendingOrdersList = action.payload;
    },
    addMedicalRecordIds: (state, action) => {
      if (!state.medicalRecordIds.includes(action.payload)) {
        state.medicalRecordIds = [...state.medicalRecordIds, action.payload];
      }
      return;
    },
    removeMedicalRecordIds: (state, action) => {
      state.medicalRecordIds = state.medicalRecordIds.filter(
        (id) => id !== action.payload
      );
    },
    resetMedicalRecordIds: (state) => {
      state.medicalRecordIds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // pharmacy orders
      .addCase(fetchPharmacyOrdersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPharmacyOrdersList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.list = action.payload.result;
        }
      })
      .addCase(fetchPharmacyOrdersList.rejected, (state) => {
        state.isLoading = false;
      })
      // search diagnostic orders
      .addCase(fetchSearchDiagnosticOrdersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSearchDiagnosticOrdersList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.searchOrders = action.payload.result;
        }
      })
      // search pharmacy orders
      .addCase(fetchPharmacySearchOrdersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPharmacySearchOrdersList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.searchOrders = action.payload.result;
        }
      })
      .addCase(fetchPharmacySearchOrdersList.rejected, (state) => {
        state.isLoading = false;
      })
      //infinite scroll
      .addCase(fetchMoreOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMoreOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.list = [...state.list, ...action.payload.result];
        }
      })
      .addCase(fetchMoreOrders.rejected, (state) => {
        state.isLoading = false;
      })
      // pharmacy request orders
      .addCase(fetchPharmacyRequestOrdersList.pending, (state) => {
        state.isPharmacyRequestOrderLoading = true;
      })
      .addCase(fetchPharmacyRequestOrdersList.fulfilled, (state, action) => {
        state.isPharmacyRequestOrderLoading = false;
        if ("result" in action.payload) {
          state.pendingOrdersList = action.payload.result;
        }
      })
      .addCase(fetchPharmacyRequestOrdersList.rejected, (state) => {
        state.isPharmacyRequestOrderLoading = false;
      })
      //infinite scroll
      .addCase(fetchMorePharmacyRequestOrdersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchMorePharmacyRequestOrdersList.fulfilled,
        (state, action) => {
          state.isLoading = false;
          if ("result" in action.payload) {
            state.pendingOrdersList = [
              ...state.pendingOrdersList,
              ...action.payload.result,
            ];
          }
        }
      )
      .addCase(fetchMorePharmacyRequestOrdersList.rejected, (state) => {
        state.isLoading = false;
      })
      // pharmacy profile
      .addCase(fetchPharmacyProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPharmacyProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          let result: any = action.payload.result;
          if (result) {
            const values = convertKeysToCamelCase(result);
            state.profile = values;
          }
        }
      })
      .addCase(fetchPharmacyProfile.rejected, (state) => {
        state.isLoading = false;
      })
      // pharmacy sales
      .addCase(fetchPharmacyProfileAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPharmacyProfileAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.sales = action.payload.result;
        }
      })
      .addCase(fetchPharmacyProfileAnalytics.rejected, (state) => {
        state.isLoading = false;
      })
      // diagnostic orders
      .addCase(fetchDiagnosticTestOrdersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDiagnosticTestOrdersList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.list = action.payload.result;
        }
      })
      .addCase(fetchMoreDiagnosticTestOrdersList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchMoreDiagnosticTestOrdersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMoreDiagnosticTestOrdersList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.list = [...state.list, ...action.payload.result];
        }
      })
      .addCase(fetchDiagnosticTestOrdersList.rejected, (state) => {
        state.isLoading = false;
      })

      // diagnostic request orders
      .addCase(fetchDiagnosticRequestOrdersList.pending, (state) => {
        state.isPharmacyRequestOrderLoading = true;
      })
      .addCase(fetchDiagnosticRequestOrdersList.fulfilled, (state, action) => {
        state.isPharmacyRequestOrderLoading = false;
        if ("result" in action.payload) {
          state.pendingOrdersList = action.payload.result;
        }
      })
      .addCase(fetchDiagnosticRequestOrdersList.rejected, (state) => {
        state.isPharmacyRequestOrderLoading = false;
      })
      // diagnostic profile
      .addCase(fetchDiagnosticProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDiagnosticProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.profile = action.payload.result;
        }
      })
      .addCase(fetchDiagnosticProfile.rejected, (state) => {
        state.isLoading = false;
      })

      // diagnostic sales
      .addCase(fetchDiagnosticProfileAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDiagnosticProfileAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.sales = action.payload.result;
        }
      })
      .addCase(fetchDiagnosticProfileAnalytics.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  saveSelectedOrder,
  saveSelectedOrderRequest,
  setIsPendingOrderRequestsOpen,
  setIsViewPrescriptionOpen,
  updateOrderList,
  updatePendingOrderList,
  addMedicalRecordIds,
  removeMedicalRecordIds,
  resetMedicalRecordIds,
  updateSearchOrderList,
} = pharmacyClinicDiagnostic.actions;
export default pharmacyClinicDiagnostic.reducer;
