import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BottomNavigationAction,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigation as Navigation } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import GetImages from "../../../../GetImages";
import Button from "../../../../Button";
import Overlays from "./Overlays";
import OrdersRequest from "../../../../../views/PharmacyClinicDiagnostic/OrdersRequest";

import { IRoleType } from "../../../../../models/role";
import {
  IAddPatient,
  IProfileDropdownEnums,
  IModalShowHide,
  IPatientExistOrNotModule,
  IOrderDateRangeParams,
} from "../../../../../models/doctor";
import { NavDropdownType } from "../../../../../models";

import { style } from "./style";

import { storage } from "../../../../../utils/Storage";
import { topBarDropdownDispatchingEnums, isClinic } from "../../../../../utils";
import {
  doctorPharmacyDiagnosticDesktopTopNav,
  doctorPharmacyDiagnosticDropdownNavItems,
} from "../../../../../utils/common";

import NavImage from "../../../../../assets/navImage.png";

import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  setAddPatient,
  setTopBarDropdownItems,
} from "../../../../../store/slices/doctor/topNavigation";
import {
  resetPatientDetailErrorMessages,
  setModuleName,
} from "../../../../../store/slices/doctor/checkMobilePatientExistOrNot";
import {
  resetAdmitPatientDetail,
  setEditAdmitPatient,
  setReAdmitPatient,
} from "../../../../../store/slices/doctor/ipDashboard";
import {
  fetchDiagnosticRequestOrdersList,
  fetchMorePharmacyRequestOrdersList,
  fetchPharmacyRequestOrdersList,
  saveSelectedOrder,
  setIsPendingOrderRequestsOpen,
  updateOrderList,
} from "../../../../../store/slices/pharmacyClinicDiagnostic";
import { setIsShowUniversalSearch } from "../../../../../store/slices/config";
import usePagination from "../../../../../utils/hooks/usePagination";

interface INavItemInfo {
  path: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  label: string;
}

interface IChildMethods {
  createAppointmentHandler: () => void;
}

const DoctorPharmacyDiagnosticTopNav: React.FC = () => {
  const overlayRef = useRef<IChildMethods>(null);

  const location = useLocation();
  const { handleFetch } = usePagination<IOrderDateRangeParams>({
    fetchFn: fetchPharmacyRequestOrdersList,
    fetchNextFn: fetchMorePharmacyRequestOrdersList,
  });

  const navIndex = location.state?.navItem || 0;

  const [navItems, setNavItems] = useState<INavItemInfo[]>();
  const [showAddPatient, setShowAddPatient] = useState(null);
  const [showDoctorProfile, setShowDoctorProfile] = useState(null);

  const { isPendingOrderRequestsOpen, pendingOrdersList } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );

  const dispatch = useAppDispatch();

  const intl = useIntl();
  const navigate = useNavigate();

  const roleType = storage.getSessionToken("roleType");

  const allRolesExceptPatient =
    roleType === IRoleType.DOCTOR ||
    roleType === IRoleType.PHARMACY ||
    roleType === IRoleType.DIAGNOSTIC_CENTER;

  const pharmacyOrDiagnosticRole =
    roleType === IRoleType.PHARMACY || roleType === IRoleType.DIAGNOSTIC_CENTER;

  const doctorOrJrDoctorRole =
    roleType === IRoleType.DOCTOR || roleType === IRoleType.JR_DOCTOR;

  const isClinicRole = isClinic();

  const doctorPharmacyDiagnosticNavigation = useMemo(
    doctorPharmacyDiagnosticDesktopTopNav,
    [isClinicRole]
  );

  const openCloseMobileCheckOTPVerify = (
    field: IAddPatient,
    value: boolean
  ) => {
    dispatch(
      setAddPatient({
        field,
        value,
      })
    );
  };

  const addPatientInfo = [
    {
      iconName: "ScheduleSelectedSmallIcon",
      text: "appointment",
      handler: () => {
        dispatch(setModuleName(IPatientExistOrNotModule.APPOINTMENT));
        openCloseMobileCheckOTPVerify(IAddPatient.APPOINTMENT, true);
        createAppointmentHandler();
        handleShowHideDropDown(
          IModalShowHide.HIDE,
          NavDropdownType.ADD_PATIENT
        );
      },
    },
    {
      iconName: "ClinicSmallIcon",
      text: "admit_patient",
      handler: () => {
        dispatch(setEditAdmitPatient(false));
        dispatch(setReAdmitPatient(false));
        dispatch(resetAdmitPatientDetail());
        dispatch(setModuleName(IPatientExistOrNotModule.ADMIT_PATIENT));
        openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, true);
        handleShowHideDropDown(
          IModalShowHide.HIDE,
          NavDropdownType.ADD_PATIENT
        );
        dispatch(resetPatientDetailErrorMessages());
      },
    },
  ];

  const setNavigationItemsByRoleType = () => {
    if (allRolesExceptPatient) {
      setNavItems(doctorPharmacyDiagnosticNavigation[roleType]);
    }
  };

  const navigateToPage = (value: number) => {
    if (allRolesExceptPatient) {
      navigate(doctorPharmacyDiagnosticNavigation[roleType][value].path, {
        state: { navItem: value },
      });
    }
  };

  const handleShowHideDropDown = (
    type: IModalShowHide,
    dropDownType: NavDropdownType,
    event?: any
  ) => {
    if (type === IModalShowHide.HIDE) {
      dropDownType === NavDropdownType.ADD_PATIENT
        ? setShowAddPatient(null)
        : setShowDoctorProfile(null);
      return;
    }
    dropDownType === NavDropdownType.ADD_PATIENT
      ? setShowAddPatient(event.currentTarget)
      : setShowDoctorProfile(event.currentTarget);
  };

  const openCloseDoctorProfileDropdown = useCallback(
    (identifier: IProfileDropdownEnums) => {
      setShowDoctorProfile(null);
      setShowAddPatient(null);
      dispatch(
        setTopBarDropdownItems(topBarDropdownDispatchingEnums[identifier])
      );
    },
    []
  );

  const createAppointmentHandler = () => {
    if (overlayRef?.current) {
      overlayRef?.current?.createAppointmentHandler();
    }
  };

  useEffect(() => {
    dispatch(saveSelectedOrder(null));
    setNavigationItemsByRoleType();
    if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
      dispatch(fetchDiagnosticRequestOrdersList({}));
    } else if (roleType === IRoleType.PHARMACY) {
      handleFetch({});
    }
  }, []);

  return (
    <>
      <Grid item sx={style.navigationContainer}>
        <Navigation
          showLabels
          value={navIndex}
          sx={style.navigation}
          onChange={(event, newValue) => {
            navigateToPage(newValue);
          }}
        >
          {navItems &&
            navItems.map((nav, index) => (
              <BottomNavigationAction
                label={intl.formatMessage({
                  id: nav.label,
                  defaultMessage: "Nav Items",
                })}
                icon={navIndex === index ? nav.selectedIcon : nav.icon}
                key={index}
                sx={style.navigationItemContainer}
              />
            ))}
        </Navigation>
        <Grid item sx={style.searchAppointmentInputContainer}>
          <input
            placeholder={intl.formatMessage({
              id: "search_appointments_here",
              defaultMessage: "Search appointments here",
            })}
            onChange={(e) =>
              e.target.value.length > 0
                ? dispatch(setIsShowUniversalSearch(true))
                : dispatch(setIsShowUniversalSearch(false))
            }
            onClick={() => dispatch(setIsShowUniversalSearch(true))}
          />
          <GetImages name="NavSearchIcon" width="24" height="24" />
        </Grid>
        <Grid item>
          {pharmacyOrDiagnosticRole && (
            <Grid
              item
              sx={style.requestPharmacyOrders}
              onClick={() => dispatch(setIsPendingOrderRequestsOpen())}
            >
              <GetImages
                name={
                  pendingOrdersList?.length > 0
                    ? "RequestNotificationDotIcon"
                    : "EyeOrangeIcon"
                }
                width="28"
                height="24"
              />
              <Typography component={"p"}>Request</Typography>
            </Grid>
          )}
        </Grid>
        {isClinicRole && (
          <Grid item sx={style.makeAppointmentBtn}>
            <Button btnTrigger={createAppointmentHandler}>
              Make Appointment
            </Button>
          </Grid>
        )}
        <Grid item sx={style.addPatientDoctorImageContainer}>
          {doctorOrJrDoctorRole && (
            <Grid item sx={style.btnContainer}>
              <Button
                btnTrigger={(event) =>
                  handleShowHideDropDown(
                    IModalShowHide.SHOW,
                    NavDropdownType.ADD_PATIENT,
                    event
                  )
                }
              >
                <FormattedMessage
                  id="add_patient"
                  defaultMessage="Add Patient"
                />
              </Button>

              <Menu
                anchorEl={showAddPatient}
                open={!!showAddPatient}
                onClose={() =>
                  handleShowHideDropDown(
                    IModalShowHide.HIDE,
                    NavDropdownType.ADD_PATIENT
                  )
                }
                sx={style.menuContainer}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {addPatientInfo.map((patient, index) => (
                  <MenuItem
                    onClick={patient.handler}
                    key={index}
                    sx={style.menuItemContainer}
                  >
                    <ListItemIcon>
                      <GetImages
                        name={patient.iconName}
                        width="24"
                        height="20"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography component={"p"} sx={style.dropdownText}>
                          <FormattedMessage
                            id={patient.text}
                            defaultMessage="Add Patient Drop"
                          />
                        </Typography>
                      }
                    />
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}
          <Grid item sx={style.doctorImageContainer}>
            <img
              src={NavImage}
              width={48}
              height={48}
              onClick={(event) =>
                handleShowHideDropDown(
                  IModalShowHide.SHOW,
                  NavDropdownType.PROFILE,
                  event
                )
              }
            />
            <Menu
              anchorEl={showDoctorProfile}
              open={!!showDoctorProfile}
              onClose={() =>
                handleShowHideDropDown(
                  IModalShowHide.HIDE,
                  NavDropdownType.PROFILE
                )
              }
              sx={style.menuContainer}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {roleType &&
                doctorPharmacyDiagnosticDropdownNavItems[roleType]?.map(
                  (doctorProfile: any, index: number) => (
                    <MenuItem
                      onClick={() =>
                        openCloseDoctorProfileDropdown(doctorProfile.identifier)
                      }
                      key={index}
                      sx={style.menuItemContainer}
                    >
                      <ListItemIcon>
                        <GetImages
                          name={doctorProfile.iconName}
                          width="24"
                          height="20"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography sx={style.dropdownText} component={"p"}>
                            <FormattedMessage
                              id={doctorProfile.text}
                              defaultMessage="Drop Down Item"
                            />
                          </Typography>
                        }
                      />
                    </MenuItem>
                  )
                )}
            </Menu>
          </Grid>
        </Grid>
        <Overlays
          ref={overlayRef}
          openCloseDoctorProfileDropdown={openCloseDoctorProfileDropdown}
          openCloseMobileCheckOTPVerify={openCloseMobileCheckOTPVerify}
        />
      </Grid>
      {isPendingOrderRequestsOpen && <OrdersRequest />}
    </>
  );
};

export default DoctorPharmacyDiagnosticTopNav;
