import React, { useEffect, useReducer, useState } from "react";
import { Grid, Stack } from "@mui/material";

import Modal from "../../Modal";
import Title from "../../Title";
import { InputField } from "../../InputField";
import Button from "../../Button";
import ChipSelect from "../../ChipSelect";
import ChipMultiSelect from "../../ChipMultiSelect";
import Slider from "../../Slider";
import AccordionWithTitle from "../AccordionWithTitle";
import MedicineTestSuggestions from "../MedicineTestSuggestions";

import style from "./index.module.css";

import { IButtonVariant } from "../../../models/button";
import {
  IMedicineTestSuggestionData,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";
import { IMedicine, ITest } from "../../../models/ApiRequestResponse/doctor";

import { transformClasses } from "../../../utils";
import { INotifyEnum, notify } from "../../../utils/toaster";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setMedicineTest } from "../../../store/slices/doctor/prescription";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  type: ITitleInputValuesListEnums;
}

const labelByType: any = {
  heading: {
    [ITitleInputValuesListEnums.MEDICINE]: "Medicine",
    [ITitleInputValuesListEnums.TEST]: "Test",
  },
  input: {
    [ITitleInputValuesListEnums.MEDICINE]: "Medicine",
    [ITitleInputValuesListEnums.TEST]: "Test Name",
  },
};

const initialState = {
  testId: "0",
  name: "",
  note: "",
  medicineId: "0",
  mealType: "",
  mealTypeTime: "",
  whenText: "",
  frequency: "",
  frequencyDays: 1,
  frequencyText: "",
};

type IMedicineTestAdd = IMedicine & ITest;
const addMedicineTestValues = (state: IMedicineTestAdd, action: any) => {
  switch (action.type) {
    case ITitleInputValuesListEnums.TEST:
    case ITitleInputValuesListEnums.MEDICINE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const CreateMedicineTestModal: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler, type } = props;
  const [mealType, setMealType] = useState<string[]>([]);
  const [daysFreq, setDaysFreq] = useState<string[]>([]);

  const [mealTypeTime, setMealTypeTime] = useState<string>("");
  const [state, dispatch] = useReducer(addMedicineTestValues, initialState);

  const dispatchMedicineTest = useAppDispatch();
  const prescription = useAppSelector((state) => state.prescription);

  const mealTypeList = prescription?.config?.mealType
    ?.split(",")
    .map((item, index) => ({ value: item, available: true }));
  const mealTypTimeList = prescription?.config?.mealTypeTime
    ?.split(",")
    .map((item, index) => ({ label: item, value: item }));
  const frequencyList = prescription?.config?.frequency
    .split(",")
    .map((freq, index) => ({ value: freq, available: true }));
  const frequencyDays = prescription?.config?.frequencyDaysDefault?.split(",");

  const getMedicineTestDetailHandler = (
    values: IMedicineTestSuggestionData,
    type: ITitleInputValuesListEnums
  ) => {
    const { name, medicineId, testId } = values;
    const isMedicine = type === ITitleInputValuesListEnums.MEDICINE;
    dispatch({
      type,
      field: isMedicine ? "medicineId" : "testId",
      value: isMedicine ? medicineId : testId,
    });
    dispatch({
      type,
      field: "name",
      value: name,
    });
  };

  const selectedMealTypesList = (selectedValues: string[]) => {
    const result: string[] = [];
    selectedValues.forEach((item, index) => {
      mealTypeList.forEach((val, ind) => {
        if (val.value === item) {
          result.push(val.value);
        }
      });
    });
    dispatch({
      type: ITitleInputValuesListEnums.MEDICINE,
      field: "mealType",
      value: result.join(","),
    });
    setMealType(result);
  };

  const selectedMedicineFrequency = (selectedValues: string[]) => {
    const result: string[] = [];
    selectedValues.forEach((item, index) => {
      frequencyList.forEach((val, ind) => {
        if (val.value === item) {
          result.push(val.value);
        }
      });
    });
    dispatch({
      type: ITitleInputValuesListEnums.MEDICINE,
      field: "frequency",
      value: result.join(","),
    });
    setDaysFreq(result);
  };

  const handleFreqValue = (value: number) => {
    dispatch({
      type: ITitleInputValuesListEnums.MEDICINE,
      field: "frequencyDays",
      value: value,
    });
    dispatch({
      type,
      field: "frequencyText",
      value: `${state.frequency} for ${value} Days`,
    });
  };

  const handleMedicineTestNoteInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: type,
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleMedicineTestSubmit = () => {
    if (state.name.length === 0) {
      notify(
        INotifyEnum.ERROR,
        "Please fill in the empty fields before saving"
      );
      return;
    }
    const {
      frequency,
      frequencyDays,
      frequencyText,
      mealType,
      mealTypeTime,
      medicineId,
      name,
      note,
      testId,
      whenText,
    } = state;
    dispatchMedicineTest(
      setMedicineTest({
        identifier: type,
        value: {
          name,
          note,
          ...(type === ITitleInputValuesListEnums.TEST && { testId }),
          ...(type === ITitleInputValuesListEnums.MEDICINE && {
            medicineId,
            mealType,
            mealTypeTime,
            whenText,
            frequency,
            frequencyDays,
            frequencyText,
          }),
        },
      })
    );
    closeHandler();
  };

  useEffect(() => {
    dispatch({
      type: ITitleInputValuesListEnums.MEDICINE,
      field: "mealTypeTime",
      value: mealTypeTime,
    });
    dispatch({
      type,
      field: "whenText",
      value: `${mealTypeTime} ${state.mealType}`,
    });
  }, [mealTypeTime]);

  const handleChangeFreqInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFreqValue(Number(e.target.value));
  };

  const handleScroll = (e: React.WheelEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement;
    inputElement.blur();
  };

  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler}>
      <Grid item className={style.container}>
        <Title>{labelByType.heading[type]}</Title>
        <Grid item className={style.inputContainer}>
          <MedicineTestSuggestions
            label={labelByType.input[type]}
            type={type}
            getMedicineTestDetailHandler={getMedicineTestDetailHandler}
            closeSearch={closeHandler}
            hideTrashIcon={true}
            isInDrawer={true}
          />
        </Grid>
        {type === ITitleInputValuesListEnums.MEDICINE && (
          <>
            <Grid
              item
              className={transformClasses(
                style.accordionContainer,
                style.mealTypeContainer
              )}
            >
              <AccordionWithTitle
                subTitle={`${mealTypeTime} ${state?.mealType}`}
                title="When"
              >
                <Grid item className={style.mealTypeChipContainer}>
                  <ChipMultiSelect
                    value={mealType}
                    list={mealTypeList}
                    selectedValuesList={selectedMealTypesList}
                  />
                </Grid>
                <Grid item className={style.mealTimeContainer}>
                  <ChipSelect
                    data={mealTypTimeList}
                    selectedValue={mealTypeTime}
                    setSelectedValue={setMealTypeTime}
                    muiclass={{
                      border: "0.1rem solid var(--gray-200)",
                      padding: "0.8rem 0.5rem",
                      height: "3.2rem",
                      minWidth: "5.6rem",
                      maxWidth: "10rem",
                    }}
                  />
                </Grid>
              </AccordionWithTitle>
            </Grid>
            <Grid item className={transformClasses(style.accordionContainer)}>
              <AccordionWithTitle
                subTitle={
                  state?.frequencyText
                    ? `${state?.frequency} for ${state?.frequencyDays} Days`
                    : ""
                }
                title="Frequency & Duration"
              >
                <Stack direction="row" className={style.freqDurationContainer}>
                  <ChipMultiSelect
                    shouldWrap={false}
                    value={daysFreq}
                    list={frequencyList}
                    selectedValuesList={selectedMedicineFrequency}
                  >
                    <input
                      type="number"
                      className={style.freqDurationInput}
                      placeholder="Type here"
                      onChange={handleChangeFreqInput}
                      onWheel={handleScroll}
                    />
                  </ChipMultiSelect>
                </Stack>
                <Grid item className={style.selectedMedicineFrequencySlider}>
                  <Slider
                    min={+frequencyDays[0]}
                    max={+frequencyDays[frequencyDays.length - 1]}
                    handleSliderValue={handleFreqValue}
                  />
                </Grid>
              </AccordionWithTitle>
            </Grid>
          </>
        )}
        <Grid item className={style.inputContainer}>
          <InputField
            value={state.note}
            onChange={handleMedicineTestNoteInput}
            label={"Note"}
            placeholder={"Note"}
            name="note"
            fullWidth
          />
        </Grid>
        <Grid item className={style.btnContainer}>
          <Grid item className={style.cancelBtn}>
            <Button variant={IButtonVariant.WHITE} btnTrigger={closeHandler}>
              Cancel
            </Button>
          </Grid>
          <Button btnTrigger={handleMedicineTestSubmit}>Save</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateMedicineTestModal;
