import { useEffect, useState } from "react";
import { Slider as CustomSlider } from "@mui/material";

interface IProps {
  min: number;
  max: number;
  handleSliderValue: (value: number) => void;
  label?: string;
  value?: number;
}
const Slider: React.FC<IProps> = (props) => {
  const { min, max, label = "Days", handleSliderValue, value = 0 } = props;
  const [sliderValue, setSliderValue] = useState<number>(value ?? 0);

  const handleChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
    handleSliderValue(newValue);
  };
  return (
    <CustomSlider
      value={sliderValue}
      onChange={handleChange}
      valueLabelDisplay="on"
      min={min}
      max={max}
      sx={{
        width: "90%",
        color: "primary.main",
        "& .MuiSlider-thumb": {
          borderRadius: "0.8rem",
          background: "var(--orange-500)",
          minWidth: "7rem",
          height: "3.2rem",
          padding: "0.8rem 1.2rem",
          "&:before": {
            display: "none",
          },
        },
        "& .MuiSlider-rail": {
          height: "0.8rem",
          backgroundColor: "var(--gray-50)",
          border: "none",
        },
        "& .MuiSlider-track": {
          height: "0.8rem",
          backgroundColor: "var(--orange-500)",
          border: "none",
        },
        "& .MuiSlider-valueLabel": {
          top: "2.6rem",
          color: "var(--neutral-100)",
          fontSize: "1.2rem",
          fontWeight: "400",
          backgroundColor: "transparent",
          "&:after": {
            content: `"${label}"`,
            marginLeft: "0.2rem",
          },
        },
      }}
    />
  );
};

export default Slider;
