import { lazy } from "react";
import HealthHubWrapper from "../../components/HealthHubWrapper";
import BlogDetailsPage from "../../views/Patient/BlogDetails";
import DiseaseDetailsPage from "../../views/Patient/DiseaseDetails";
import PlayListPage from "../../views/Patient/PlaylistPage";

const FindDoctor = lazy(() => import("../../views/Doctor/FindDoctor"));
const DoctorProfile = lazy(() => import("../../views/Common/DoctorProfile"));
const Home = lazy(() => import("../../views/Patient/Home"));
const Faq = lazy(() => import("../../views/Patient/FAQ"));
const HealthAssessment = lazy(
  () => import("../../views/Patient/HealthAssessment")
);
const BlogListPage = lazy(() => import("../../views/Patient/BlogListPage"));
const Orders = lazy(() => import("../../views/Patient/Home/Orders"));
const HealthHub = lazy(() => import("../../views/Patient/HealthHub"));
const VideoDetails = lazy(() => import("../../views/Patient/VideoDetails"));
const Medicines = lazy(() => import("../../views/Patient/Medicines"));
const Tests = lazy(() => import("../../views/Patient/Tests"));
const PatientMedicalRecords = lazy(
  () => import("../../components/PatientMedicalRecords")
);

const PatientRoutes = [
  { path: "/home", component: <Home /> },
  { path: "/find-doctor", component: <FindDoctor /> },
  { path: "/medicines", component: <Medicines /> },
  { path: "/test-reports", component: <Tests /> },
  { path: "/doctor-profile", component: <DoctorProfile /> },
  { path: "/medical-records", component: <PatientMedicalRecords /> },
  { path: "/faq", component: <Faq /> },
  { path: "/health-assessment", component: <HealthAssessment /> },
  { path: "/order-list", component: <Orders /> },
  {
    path: "/health-hub",
    component: (
      <HealthHubWrapper>
        <HealthHub />
      </HealthHubWrapper>
    ),
  },
  {
    path: "/health-hub/video",
    component: (
      <HealthHubWrapper>
        <VideoDetails />
      </HealthHubWrapper>
    ),
  },
  {
    path: "/health-hub/blog-list",
    component: (
      <HealthHubWrapper>
        <BlogListPage />
      </HealthHubWrapper>
    ),
  },
  {
    path: "/health-hub/playlist",
    component: (
      <HealthHubWrapper>
        <PlayListPage />
      </HealthHubWrapper>
    ),
  },
  {
    path: "/health-hub/blog",
    component: (
      <HealthHubWrapper>
        <BlogDetailsPage />
      </HealthHubWrapper>
    ),
  },
  {
    path: "/health-hub/disease",
    component: (
      <HealthHubWrapper>
        <DiseaseDetailsPage />
      </HealthHubWrapper>
    ),
  },
];

export default PatientRoutes;
