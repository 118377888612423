import React, { useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import style from "./index.module.css";

import { styles } from "../../utils/styles";
import {
  doctorDegreeName,
  doctorSpeakLanguages,
  getDateTime,
  transformClasses,
} from "../../utils";
import useIsMobile from "../../utils/hooks/useIsMobile";

import Button from "../Button";
import Banner from "../Banner";
import GetImages from "../GetImages";
import ContactForm from "../ContactForm";
import GenericCardHeader from "../GenericCardHeader";

import { IButtonVariant } from "../../models/button";
import {
  IAppointmentCreation,
  IAppointmetEnums,
  IFindDoctorAvailabilityPlatform,
  IDoctorProfileStatus,
  IMakeAppointmentEvent,
  IDoctorAvailabilityPlatform,
} from "../../models/doctor";
import { IQualificationDetail } from "../../models/ApiRequestResponse/patient";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { checkoutDetail } from "../../store/slices/patient/findDoctor";
import MakeAppointment from "../DoctorProfile/MakeAppointment";
import Dialog from "../Dialog";
import { fetchDoctorProfile } from "../../store/slices/doctor/profile";

export interface IVideo {
  time?: string;
  price?: string;
}

export interface IClinic {
  time?: string;
  price?: string;
}
export interface ITimePrice {
  video: IVideo;
  clinic: IClinic;
}

interface IProps {
  doctor_id: string;
  doctor_name: string;
  year_of_experience: number;
  speciality: string;
  qualification_details: IQualificationDetail[];
  language: string[];
  doctor_photo_url: string;
  distance: number;
  clinic_address: string;
  clinic_name: string;
  reviews: number;
  expertise: string;
  type: IFindDoctorAvailabilityPlatform;
  video_consultation_fee: number;
  next_video_consultation_slot: string;
  in_clinic_consultation_fee: number;
  next_in_clinic_slot: string;
  currentIndex: number;
}

const platformMap: any = {
  [IFindDoctorAvailabilityPlatform.VIDEO_CALL]:
    IDoctorAvailabilityPlatform.VIDEO_CALL,
  [IFindDoctorAvailabilityPlatform.IN_CLINIC]:
    IDoctorAvailabilityPlatform.IN_CLINIC,
};

const FindDoctorCard: React.FC<IProps> = (props) => {
  const {
    doctor_id,
    doctor_name,
    year_of_experience,
    speciality,
    qualification_details,
    language,
    doctor_photo_url,
    distance,
    clinic_address,
    clinic_name,
    reviews,
    expertise,
    type,
    video_consultation_fee,
    next_video_consultation_slot,
    in_clinic_consultation_fee,
    next_in_clinic_slot,
    currentIndex,
  } = props;

  const [isAppointmentCreation, setIsAppointmentCreation] =
    useState<IAppointmentCreation>({
      creation: false,
      success: false,
    });
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [platform, setPlatform] = useState<IDoctorAvailabilityPlatform>();

  const { isMobile } = useIsMobile();

  const intl = useIntl();

  const dispatch = useAppDispatch();
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);

  const closeAppointmentModal = () => {
    const schedulesValues: any = {};
    for (let key in isAppointmentCreation) {
      schedulesValues[key] = false;
    }
    setIsAppointmentCreation(schedulesValues);
  };

  const openAppointmentModalHandler = (type: IAppointmetEnums) => {
    const schedulesValues: any = {};
    for (let key in isAppointmentCreation) {
      schedulesValues[key] = false;
    }
    switch (type) {
      case IAppointmetEnums.CREATION:
        setIsAppointmentCreation({
          ...schedulesValues,
          creation: true,
        });
        return;
      case IAppointmetEnums.SUCCESS:
        setIsAppointmentCreation({
          ...schedulesValues,
          success: true,
        });
        return;
      default:
        closeAppointmentModal();
        return;
    }
  };

  const selectedTabOpenAppointmentModalHandler = (
    type: IAppointmetEnums,
    tab: IMakeAppointmentEvent,
    doctorPlatform: IDoctorAvailabilityPlatform
  ) => {
    dispatch(fetchDoctorProfile(doctor_id));
    dispatch(
      checkoutDetail({
        doctorId: doctor_id,
        doctorImage: doctor_photo_url,
        doctorName: doctor_name,
        bio: `${year_of_experience} yrs • ${speciality} • ${doctorDegreeName(
          qualification_details
        )}`,
        languages: doctorSpeakLanguages(language),
        type:
          tab === 0
            ? IDoctorAvailabilityPlatform.VIDEO_CALL
            : IDoctorAvailabilityPlatform.IN_CLINIC,
      })
    );
    setSelectedTab(tab);
    setPlatform(doctorPlatform);
    openAppointmentModalHandler(type);
  };

  const activeTab = useMemo(() => {
    if (platform === IDoctorAvailabilityPlatform.BOTH) {
      return selectedTab;
    } else {
      return platform === IDoctorAvailabilityPlatform.IN_CLINIC
        ? IMakeAppointmentEvent.IN_CLINIC
        : IMakeAppointmentEvent.VIDEO_CALL;
    }
  }, [platform, selectedTab]);

  if (isShowUniversalSearch) {
    return (
      <Grid item className={style.cardContainer}>
        <GenericCardHeader
          doctorId={doctor_id}
          image={doctor_photo_url}
          name={doctor_name}
          bio={`${year_of_experience} yrs • ${speciality} • ${doctorDegreeName(
            qualification_details
          )}`}
          languages={doctorSpeakLanguages(language)}
          status={IDoctorProfileStatus.VERIFIED}
        />
        {/* Address */}
        <Grid item className={style.addressContainer}>
          <GetImages name="LocationIcon" width="16" height="16" />
          <Typography component={"p"} className={style.addressText}>
            {Math.round(distance)}km • {clinic_address} • {clinic_name}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <>
      <Grid item className={style.cardContainer}>
        <Grid item className={style.doctorCardAvailabilityOptionContainer}>
          <Grid item className={style.doctorDetailContainer}>
            <GenericCardHeader
              doctorId={doctor_id}
              image={doctor_photo_url}
              name={doctor_name}
              bio={`${year_of_experience} yrs • ${speciality} • ${doctorDegreeName(
                qualification_details
              )}`}
              languages={doctorSpeakLanguages(language)}
              status={IDoctorProfileStatus.VERIFIED}
            />
            {/* Address */}
            <Grid item className={style.addressContainer}>
              <GetImages name="LocationIcon" width="16" height="16" />
              <Typography component={"p"} className={style.addressText}>
                {Math.round(distance)}km • {clinic_address} • {clinic_name}
              </Typography>
            </Grid>
          </Grid>
          {/* Availability Options */}
          <Grid item className={style.availabilityBtns}>
            {(type === IFindDoctorAvailabilityPlatform.BOTH ||
              type === IFindDoctorAvailabilityPlatform.VIDEO_CALL) && (
              <Grid
                item
                width={"100%"}
                className={
                  type === IFindDoctorAvailabilityPlatform.BOTH
                    ? style.availabilityVideo
                    : ""
                }
              >
                <Button
                  variant={IButtonVariant.WHITE}
                  btnTrigger={() =>
                    selectedTabOpenAppointmentModalHandler(
                      IAppointmetEnums.CREATION,
                      IMakeAppointmentEvent.VIDEO_CALL,
                      platformMap[type] || IDoctorAvailabilityPlatform.BOTH
                    )
                  }
                >
                  <GetImages name="VideoIcon" width="20" height="20" />
                  <Typography component={"p"} sx={styles.ml6}>
                    {intl.formatMessage({
                      id: "video_call",
                      defaultMessage: "Video Call",
                    })}
                  </Typography>
                </Button>
                <Typography
                  component={"p"}
                  className={transformClasses(
                    style.doctorPriceTimeLabel,
                    style.doctorTime
                  )}
                >
                  {<GetImages name="GreenDotIcon" width="6" height="7" />}{" "}
                  {next_video_consultation_slot &&
                    getDateTime(next_video_consultation_slot)}
                  {"  "}
                  <Typography
                    component={"span"}
                    className={transformClasses(
                      style.doctorPriceTimeLabel,
                      style.doctorPrice
                    )}
                  >
                    ₹{video_consultation_fee ?? 0}
                  </Typography>
                </Typography>
              </Grid>
            )}
            {(type === IFindDoctorAvailabilityPlatform.BOTH ||
              type === IFindDoctorAvailabilityPlatform.IN_CLINIC) && (
              <Grid item width={"100%"}>
                <Button
                  variant={IButtonVariant.WHITE}
                  btnTrigger={() =>
                    selectedTabOpenAppointmentModalHandler(
                      IAppointmetEnums.CREATION,
                      IMakeAppointmentEvent.IN_CLINIC,
                      platformMap[type] || IDoctorAvailabilityPlatform.BOTH
                    )
                  }
                >
                  <GetImages name="ClinicIcon" width="20" height="20" />
                  <Typography component={"p"} sx={styles.ml6}>
                    In-Clinic
                  </Typography>
                </Button>
                <Typography
                  component={"p"}
                  className={transformClasses(
                    style.doctorPriceTimeLabel,
                    style.doctorTime
                  )}
                >
                  {<GetImages name="GreenDotIcon" width="6" height="7" />}{" "}
                  {next_in_clinic_slot && getDateTime(next_in_clinic_slot)}
                  {"  "}
                  <Typography
                    component={"span"}
                    className={transformClasses(
                      style.doctorPriceTimeLabel,
                      style.doctorPrice
                    )}
                  >
                    ₹{in_clinic_consultation_fee ?? 0}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Rating */}
        <Grid item className={style.ratingContainer}>
          {reviews > 0 && (
            <Grid item className={style.ratingHeader}>
              <GetImages name="LikeIcon" width="16" height="16" />
              <Typography
                component={"p"}
                className={style.ratingText}
                sx={styles.ml2}
              >
                {reviews}{" "}
                {intl.formatMessage({
                  id: "reviews",
                  defaultMessage: "Reviews",
                })}
              </Typography>
            </Grid>
          )}
          {expertise && (
            <Typography
              component={"p"}
              className={transformClasses(style.reviewText, style.ratingText)}
            >
              {expertise}
            </Typography>
          )}
        </Grid>
      </Grid>
      {isMobile && currentIndex === 1 && (
        <Grid item className={style.doctorCallbackBanner}>
          <Banner
            title="Need help in choosing best suitable doctor?"
            desc="Let us select a doctor for you"
          />
        </Grid>
      )}
      {!isShowUniversalSearch && !isMobile && currentIndex === 1 && (
        <Grid item className={style.doctorCallbackBanner}>
          <ContactForm />
        </Grid>
      )}
      <Dialog
        closeHandler={closeAppointmentModal}
        isOpen={isAppointmentCreation.creation}
        maxWidth="sm"
        fullWidth
        paperProps={{
          sx: {
            maxWidth: "40rem",
            minHeight: "10rem",
          },
        }}
      >
        <MakeAppointment
          open={isAppointmentCreation.creation}
          doctorId={doctor_id}
          selectedTab={activeTab}
          setSelectedTab={setSelectedTab}
          openScheduleModalHandler={openAppointmentModalHandler}
          onClose={closeAppointmentModal}
          platform={platform}
        />
      </Dialog>
    </>
  );
};

export default FindDoctorCard;
