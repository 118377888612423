import React, { ChangeEvent, useLayoutEffect, useRef, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";

import GetImages from "../../GetImages";
import MealTime from "../MealDoseTimePopUp/MealTime";
import DoseFrequency from "../MealDoseTimePopUp/DoseFrequency";

import style from "./index.module.css";

import {
  IMealDoseTimeTypesEnums,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  addValuesToExistingMedicinesTest,
  removePrescriptionTemplateItem,
} from "../../../store/slices/doctor/prescription";

interface IProps {
  type: ITitleInputValuesListEnums;
  medicineTestInputShowType: ITitleInputValuesListEnums | null;
}
const MedicineTestListRemoveManagerWeb: React.FC<IProps> = (props) => {
  const { type, medicineTestInputShowType } = props;
  const [showMealDoseTimePopup, setShowMealDoseTimePopup] = useState({
    popUpType: null as IMealDoseTimeTypesEnums | null,
    selectedPopUpIndex: 0,
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const isMedicine = type === ITitleInputValuesListEnums.MEDICINE;
  const medicineOrTest = isMedicine
    ? ITitleInputValuesListEnums.MEDICINE
    : ITitleInputValuesListEnums.TEST;

  const dispatch = useAppDispatch();
  const { isTemplatesOpen } = useAppSelector(
    (state) => state.topNavigation.topBarItems
  );

  const { create }: any = useAppSelector((state) =>
    isTemplatesOpen ? state.prescription.template : state.prescription
  );

  let debounceTimeout: NodeJS.Timeout | null = null;

  const showCloseMealDoseTimePopUpHandler = (
    popUpType: IMealDoseTimeTypesEnums | null,
    selectedPopUpIndex: number
  ) => {
    setShowMealDoseTimePopup({ popUpType, selectedPopUpIndex });
  };

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "2.5rem";
    }
  }, [textareaRef]);

  const removeAddedMedicineTestHandler = (
    identifier: ITitleInputValuesListEnums,
    itemIndex: number,
    value?: string
  ) => {
    const removeItemDetails = {
      identifier,
      value,
      itemIndex,
    };
    dispatch(removePrescriptionTemplateItem(removeItemDetails));
  };

  const addNoteHandler = (
    identifier: ITitleInputValuesListEnums,
    value: string,
    itemIndex: number
  ) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      dispatch(
        addValuesToExistingMedicinesTest({
          identifier,
          field: "note",
          value,
          itemIndex,
        })
      );
    }, 100);
  };

  const handleTextareaChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
    identifier: ITitleInputValuesListEnums,
    itemIndex: number
  ) => {
    const textarea = event.currentTarget;

    textarea.style.height = "0rem";
    textarea.style.height = `${textarea.scrollHeight / 10}rem`;

    addNoteHandler(identifier, textarea.value, itemIndex);
  };

  return (
    <>
      {create &&
        create[medicineOrTest]?.length > 0 &&
        create[medicineOrTest]?.map(
          (medicineTestDetail: any, index: number) => (
            <Stack
              direction={
                type === ITitleInputValuesListEnums.TEST ? "column" : "row"
              }
              justifyContent={
                type === ITitleInputValuesListEnums.TEST
                  ? "space-between"
                  : "flex-start"
              }
              alignItems="flex-start"
              gap={type === ITitleInputValuesListEnums.TEST ? 2 : 0}
              sx={{
                width:
                  type === ITitleInputValuesListEnums.TEST
                    ? "fit-content !important"
                    : "100%",
              }}
              className={style.container}
              key={index}
            >
              <Stack
                direction={
                  type === ITitleInputValuesListEnums.TEST ? "row" : "column"
                }
                justifyContent="space-between"
                width={
                  type === ITitleInputValuesListEnums.TEST
                    ? "100%"
                    : "fit-content"
                }
                className={style.medicineTestNameContainer}
              >
                <Typography component={"p"}>
                  {medicineTestDetail?.name}
                </Typography>
                {type === ITitleInputValuesListEnums.TEST && (
                  <Grid
                    item
                    className={style.trashIcon}
                    onClick={() =>
                      removeAddedMedicineTestHandler(
                        isMedicine
                          ? ITitleInputValuesListEnums.MEDICINE
                          : ITitleInputValuesListEnums.TEST,
                        index,
                        medicineTestDetail?.name
                      )
                    }
                  >
                    <GetImages
                      name="TrashIcon"
                      width="24"
                      height="24"
                      fill="var(--red-600)"
                    />
                  </Grid>
                )}
              </Stack>
              <Grid item className={style.medicineTestActionContainer}>
                {isMedicine && (
                  <Grid item className={style.medicineActionContainer}>
                    <Grid item>
                      <Typography
                        component={"p"}
                        className={style.mealTime}
                        onClick={() =>
                          showCloseMealDoseTimePopUpHandler(
                            IMealDoseTimeTypesEnums.MEAL_TIME,
                            index
                          )
                        }
                      >
                        {medicineTestDetail?.whenText ?? "When To Take"}
                      </Typography>
                      {showMealDoseTimePopup.popUpType ===
                        IMealDoseTimeTypesEnums.MEAL_TIME &&
                        showMealDoseTimePopup.selectedPopUpIndex === index && (
                          <Grid
                            item
                            className={style.mealTimeSelectionContainer}
                          >
                            <MealTime
                              selectedIndex={index}
                              closeHandler={() =>
                                showCloseMealDoseTimePopUpHandler(null, index)
                              }
                            />
                          </Grid>
                        )}
                    </Grid>
                    <Grid item>
                      <Typography
                        component={"p"}
                        className={style.doseDays}
                        onClick={() =>
                          showCloseMealDoseTimePopUpHandler(
                            IMealDoseTimeTypesEnums.DOSE_TIME,
                            index
                          )
                        }
                      >
                        {medicineTestDetail?.frequencyText ??
                          "Frequency & Medicine"}
                      </Typography>
                      {showMealDoseTimePopup.popUpType ===
                        IMealDoseTimeTypesEnums.DOSE_TIME &&
                        showMealDoseTimePopup.selectedPopUpIndex === index && (
                          <Grid
                            item
                            className={style.doseFreqSelectionContainer}
                          >
                            <DoseFrequency
                              selectedIndex={index}
                              closeHandler={() =>
                                showCloseMealDoseTimePopUpHandler(null, index)
                              }
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                )}
                <Grid item className={style.addNote}>
                  <textarea
                    ref={textareaRef}
                    value={medicineTestDetail?.note}
                    placeholder="Add note"
                    onChange={(event) =>
                      handleTextareaChange(event, medicineOrTest, index)
                    }
                    style={{ overflow: "hidden", resize: "none" }}
                  />
                </Grid>
                {type !== ITitleInputValuesListEnums.TEST && (
                  <Grid
                    item
                    className={style.trashIcon}
                    onClick={() =>
                      removeAddedMedicineTestHandler(
                        isMedicine
                          ? ITitleInputValuesListEnums.MEDICINE
                          : ITitleInputValuesListEnums.TEST,
                        index,
                        medicineTestDetail?.name
                      )
                    }
                  >
                    <GetImages
                      name="TrashIcon"
                      width="24"
                      height="24"
                      fill="var(--red-600)"
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
          )
        )}
    </>
  );
};

export default MedicineTestListRemoveManagerWeb;
