import React, { useCallback, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import { style } from "./style";

import Dialog from "../../../components/Dialog";
import GetImages from "../../../components/GetImages";
import Modal from "../../../components/Modal";
import { InputField, TextArea } from "../../../components/InputField";
import Button from "../../../components/Button";

import { IButtonVariant } from "../../../models/button";

import { createHelpSupportTicketApi } from "../../../utils/apis/doctor/profile";
import { INotifyEnum, notify } from "../../../utils/toaster";
import { IMedicalRecordTypeEnum } from "../../../models/doctor";
import { uploadFileToS3Api } from "../../../utils/apis/doctor/prescription";
import { getFileNameFromUrl, truncateFileName } from "../../../utils";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
}
const HelpSupport: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler } = props;

  const [fields, setFields] = useState({
    problem: "",
    description: "",
  });
  const [attachments, setAttachments] = useState<string[]>([]);
  const [fileAttachments, setFileAttachments] = useState<string[]>([]);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const intl = useIntl();
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const uploadFileToS3 = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsFileUploading(true);
      const file = event.target.files?.[0];
      const formData = new FormData();
      formData.append("file", file!);
      const uploadFileToS3Response = await uploadFileToS3Api(
        IMedicalRecordTypeEnum.SUPPORT_ATTACHMENT,
        formData
      );

      if ("result" in uploadFileToS3Response) {
        const { thumbnailUrl, url } = uploadFileToS3Response?.result;
        const fileName = truncateFileName(getFileNameFromUrl(url) || "");
        setFileAttachments([fileName!, ...fileAttachments]);
        setAttachments([thumbnailUrl ?? url, ...attachments]);
      }
    } finally {
      setIsFileUploading(false);
    }
  };

  const removeAttachmentsBeforeSubmitTicket = (indexVal: number) => {
    setFileAttachments(
      fileAttachments.filter((_, index) => index !== indexVal)
    );
    setAttachments(attachments.filter((_, index) => index !== indexVal));
  };
  const pdfImageUploadHandler = (type: string) => {
    document.getElementById(type)?.click();
  };
  const handleRequestCallbackSubmit = async () => {
    try {
      const helpSupportTicketResponse = await createHelpSupportTicketApi({
        problem: fields.problem,
        description: fields.description,
        attachments,
      });
      if ("result" in helpSupportTicketResponse) {
        closeHandler();
        notify(INotifyEnum.SUCCESS, helpSupportTicketResponse.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const helpSupportScreen = useCallback(
    () => (
      <Grid item sx={style.container}>
        <Typography component={"h4"} sx={style.fieldSpacing}>
          {intl.formatMessage({
            id: "help_support",
            defaultMessage: "Help & Support",
          })}
        </Typography>
        <Grid item sx={style.fieldSpacing}>
          <InputField
            name="problem"
            value={fields.problem}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: "problem",
              defaultMessage: "Problem",
            })}
            fullWidth
          />
        </Grid>
        <Grid item sx={style.fieldSpacing}>
          <TextArea
            rows={8}
            value={fields.description}
            onChange={handleChange}
            name="description"
            placeholder={intl.formatMessage({
              id: "description",
              defaultMessage: "Description",
            })}
          />
        </Grid>
        <Grid item>
          {fileAttachments?.map((filename: any, index: number) => (
            <Grid item sx={style.pdfContainer} key={`${filename}-${index}`}>
              <FilePresentIcon sx={{ color: "var(--orange-500)" }} />
              <Typography component={"p"}>{filename}</Typography>
              <Grid
                item
                sx={style.delete}
                onClick={() => removeAttachmentsBeforeSubmitTicket(index)}
              >
                <GetImages
                  name="DiagnosticTestRecordDeleteIcon"
                  width="12"
                  height="10"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item sx={{ ...style.fieldSpacing, ...style.attachmentButton }}>
          <input
            id="attachment"
            type="file"
            onChange={uploadFileToS3}
            accept={"image/png, image/jpeg, .pdf"}
            style={{ display: "none" }}
          />
          <Button
            variant={IButtonVariant.WHITE}
            btnTrigger={() => pdfImageUploadHandler("attachment")}
          >
            <GetImages name="GallerySmallIcon" width="26" height="20" />
            {intl.formatMessage({
              id: "attachment",
              defaultMessage: "Attachment",
            })}
          </Button>
        </Grid>
        <Grid item sx={{ ...style.helpSupportActions }}>
          <Grid item sx={{ ...style.cancelBtnSpace, ...style.button }}>
            <Button variant={IButtonVariant.WHITE} btnTrigger={closeHandler}>
              {intl.formatMessage({
                id: "cancel",
                defaultMessage: "Cancel",
              })}
            </Button>
          </Grid>
          <Grid item sx={style.button}>
            <Button
              disabled={
                !(
                  fields?.problem?.length > 0 && fields?.description?.length > 0
                )
              }
              btnTrigger={handleRequestCallbackSubmit}
              isLoading={isFileUploading}
            >
              <GetImages name="TelePhoneWhiteIcon" width="26" height="20" />
              {intl.formatMessage({
                id: "request_call_back",
                defaultMessage: "Request Call back",
              })}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    ),
    [fileAttachments, fields, isFileUploading]
  );

  return (
    <>
      {isMobile || isTablet ? (
        <Modal isOpen={isOpen} closeHandler={closeHandler}>
          {helpSupportScreen()}
        </Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth={"sm"}
          isOpen={isOpen}
          closeHandler={closeHandler}
        >
          {helpSupportScreen()}
        </Dialog>
      )}
    </>
  );
};

export default HelpSupport;
