import React, { useState } from "react";
import { FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import GetImages from "../../GetImages";
import Profile from "../../../views/Patient/Profile";

import style from "./index.module.css";

import { IPatientMemberDetailEnums } from "../../../models/patient";

import { transformClasses } from "../../../utils";

interface IProps {
  type: IPatientMemberDetailEnums;
  isDefault?: boolean;
  memberId: string;
  name: string;
  age: number;
}
const MemberDetailTile: React.FC<IProps> = (props) => {
  const { type, memberId, name, age, isDefault } = props;
  const [isEditProfileShow, setIsEditProfileShow] = useState<boolean>(false);

  const initl = useIntl();
  const openCloseEditProfile = () => setIsEditProfileShow(!isEditProfileShow);

  const radioSelectionScreen = () => (
    <FormControlLabel
      value={memberId}
      className={
        type === IPatientMemberDetailEnums.CHECKOUT
          ? style.checkoutRadioButton
          : style.homeRadioButton
      }
      control={
        <Radio
          checkedIcon={
            <GetImages name="TickOrangeIcon" width="20" height="20" />
          }
          icon={<GetImages name="RadioBtnIcon" width="20" height="20" />}
        />
      }
      label={<></>}
    />
  );
  return (
    <Grid
      item
      className={
        type === IPatientMemberDetailEnums.CHECKOUT
          ? transformClasses(style.card, style.checkoutCard)
          : transformClasses(style.card, style.homeCard)
      }
    >
      {type === IPatientMemberDetailEnums.CHECKOUT && radioSelectionScreen()}
      {name || age ? (
        <>
          <Typography component={"p"} className={style.patientName}>
            {type === IPatientMemberDetailEnums.HOME
              ? name?.slice(0, 10)
              : name}
          </Typography>
          <Typography
            component={"p"}
            className={transformClasses(
              style.patientAge,
              type === IPatientMemberDetailEnums.HOME
                ? style.patientAgeHome
                : ""
            )}
          >
            Age {age}{" "}
            {type === IPatientMemberDetailEnums.HOME && isDefault && (
              <Grid item className={style.radioWrapper}>
                {radioSelectionScreen()}
              </Grid>
            )}
          </Typography>
        </>
      ) : (
        <>
          <Typography component={"p"} className={style.patientName}>
            {initl.formatMessage({
              id: "update_profile",
              defaultMessage: "Update your Profile",
            })}
          </Typography>
          <Typography
            component={"p"}
            className={style.clickHere}
            onClick={openCloseEditProfile}
          >
            {initl.formatMessage({
              id: "click_here",
              defaultMessage: "click here",
            })}
          </Typography>
        </>
      )}

      {isEditProfileShow && (
        <Profile
          isOpen={isEditProfileShow}
          closeHandler={openCloseEditProfile}
          selectedMemberId={memberId}
          type={IPatientMemberDetailEnums.HOME}
        />
      )}
    </Grid>
  );
};

export default MemberDetailTile;
