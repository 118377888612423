import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import GetImages from "../../../components/GetImages";
import Loader from "../../../components/Loader";
import DateRangePicker from "../../../components/DateRangePicker";
import SettingPreferences from "../../Patient/SettingPreferences";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDiagnosticProfile,
  fetchDiagnosticProfileAnalytics,
  fetchPharmacyProfile,
  fetchPharmacyProfileAnalytics,
} from "../../../store/slices/pharmacyClinicDiagnostic";
import { setIsSettingPreferencesOpenClose } from "../../../store/slices/config";

import { metriceLabelByStatus } from "../../../utils/common";
import {
  convertDateDDMMM,
  convertDateToTimeStamp,
  convertTimestampToISO,
  defaultCalendarDates,
  defaultCalendarDatesTimeStamp,
  downloadReport,
  timeInAmPmFormat,
} from "../../../utils";
import { getPharmacyProfileMonthlyAnalysisExportCSVApi } from "../../../utils/apis/pharmacy";
import { storage } from "../../../utils/Storage";
import { getDiagnosticProfileMonthlyAnalysisExportCSVApi } from "../../../utils/apis/diagnostic";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";
import { INotifyEnum, notify } from "../../../utils/toaster";

import { IOrderDateRangeParams } from "../../../models/doctor";
import {
  IOrderStatusEnum,
  IProfileAnalyticsEnum,
} from "../../../models/ApiRequestResponse/pharmacy";
import { IRoleType } from "../../../models/role";

import DefaultProfileImage from "../../../assets/profile.png";

interface ISalesMetrics {
  status: IOrderStatusEnum | string;
  statusCount: number | string;
}
const Profile: React.FC = () => {
  const [showDateRangeSelector, setShowDateRangeSelector] =
    useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [filters, setFilters] = useState<IOrderDateRangeParams>({
    startTime: defaultCalendarDates.startDate,
    endTime: defaultCalendarDates.endDate,
    startDate: convertTimestampToISO(+defaultCalendarDatesTimeStamp.startDate),
    endDate: convertTimestampToISO(+defaultCalendarDatesTimeStamp.endDate),
  });
  const dateSelectorModalRef = useRef<HTMLDivElement>(null);

  const roleType = storage.getSessionToken("roleType");
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const dispatch = useAppDispatch();
  const { profile, sales, isLoading } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );
  const { isSettingPreferencesOpenClose } = useAppSelector(
    (state) => state.config
  );

  const handleClickDateRangeOutside = (event: MouseEvent) => {
    if (
      dateSelectorModalRef.current &&
      !dateSelectorModalRef.current.contains(event.target as Node)
    ) {
      setShowDateRangeSelector(false);
    }
  };

  const openCloseDateRangeSelector = () => {
    if (showDateRangeSelector) {
      document.removeEventListener("mousedown", handleClickDateRangeOutside);
    } else {
      document.addEventListener("mousedown", handleClickDateRangeOutside);
    }
    setShowDateRangeSelector(!showDateRangeSelector);
  };

  const submitDateRangeValues = (start: string, end: string) => {
    setFilters({
      ...filters,
      ...(roleType === IRoleType.DIAGNOSTIC_CENTER
        ? {
            startDate: start,
            endDate: end,
          }
        : {
            startTime: dayjs(start).toISOString(),
            endTime: dayjs(end).toISOString(),
          }),
    });
    openCloseDateRangeSelector();
  };

  const SalesMetric = (props: ISalesMetrics, index: number) => {
    const { status, statusCount } = props;
    return (
      <Grid item className={style.metrics} key={`${status}-${index}`}>
        <Typography component={"h6"}>{metriceLabelByStatus[status]}</Typography>
        <Typography component={"p"}>{statusCount}</Typography>
      </Grid>
    );
  };

  const exportReport = async () => {
    try {
      setIsExporting(true);
      let responseSalesExport;
      if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
        responseSalesExport =
          await getDiagnosticProfileMonthlyAnalysisExportCSVApi({
            startTime: convertDateToTimeStamp(
              dayjs(filters?.startDate).startOf("day").toDate()
            ),
            endTime: convertDateToTimeStamp(
              dayjs(filters?.endDate).endOf("day").toDate()
            ),
          });
      } else {
        responseSalesExport =
          await getPharmacyProfileMonthlyAnalysisExportCSVApi({
            startTime: filters?.startTime,
            endTime: filters?.endTime,
          });
      }

      if ("result" in responseSalesExport) {
        downloadReport(responseSalesExport.result, "sales");
      } else {
        notify(INotifyEnum.ERROR, responseSalesExport?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsExporting(false);
    }
  };

  const calendar = useCallback(() => {
    const startDate =
      roleType === IRoleType.PHARMACY ? filters.startTime : filters.startDate;
    const endDate =
      roleType === IRoleType.PHARMACY ? filters.endTime : filters.endDate;
    const isToday =
      dayjs(startDate).isSame(dayjs(), "day") &&
      dayjs(endDate).isSame(dayjs(), "day");
    const formattedText = isToday
      ? "Today"
      : startDate !== endDate
      ? `${convertDateDDMMM(startDate!)} - ${convertDateDDMMM(endDate!)}`
      : `${convertDateDDMMM(startDate!)}`;

    return (
      <Grid
        item
        className={style.chooseDateFilter}
        onClick={openCloseDateRangeSelector}
        position={"relative"}
      >
        <GetImages name="CalenderIcon" width="20" height="20" />
        <Typography component={"p"}>{formattedText}</Typography>
      </Grid>
    );
  }, [filters]);

  const DateDisplay = useCallback(() => {
    const startDate =
      roleType === IRoleType.PHARMACY ? filters.startTime : filters.startDate;
    const endDate =
      roleType === IRoleType.PHARMACY ? filters.endTime : filters.endDate;
    const isSameMonthAndYear = dayjs(startDate).isSame(endDate, "month");

    const displayText = isSameMonthAndYear
      ? `For the Month of ${dayjs(startDate).format("MMMM, YYYY")}`
      : `From ${dayjs(startDate).format("DD MMM, YYYY")} to ${dayjs(
          endDate
        ).format("DD MMM, YYYY")}`;

    return displayText;
  }, [filters]);

  useEffect(() => {
    if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
      dispatch(fetchDiagnosticProfile());
    } else {
      dispatch(fetchPharmacyProfile());
      dispatch(fetchPharmacyProfileAnalytics({}));
    }
  }, []);

  useEffect(() => {
    if (
      roleType === IRoleType.PHARMACY &&
      filters?.startTime &&
      filters.endTime
    ) {
      dispatch(
        fetchPharmacyProfileAnalytics({
          startTime: filters.startTime,
          endTime: filters.endTime,
        })
      );
    } else {
      dispatch(
        fetchDiagnosticProfileAnalytics({
          startTime: convertDateToTimeStamp(
            dayjs(filters?.startDate).startOf("day").toDate()
          ),
          endTime: convertDateToTimeStamp(
            dayjs(filters?.endDate).endOf("day").toDate()
          ),
        })
      );
    }
  }, [filters]);

  if (isLoading) {
    return <Loader />;
  }

  if (isExporting) {
    return <Loader />;
  }

  return (
    <Grid item className={isMobile ? style.isMobileContainer : ""}>
      <Grid item className={style.pharmacyProfileContainer}>
        <Grid item className={style.pharmacyDiagnosticHeaderContainer}>
          <img
            src={profile?.profilePhoto}
            alt="Profile image"
            onError={(e: any) => {
              e.target.src = DefaultProfileImage;
            }}
          />
          {(isMobile || isTablet) && (
            <Grid item className={style.pharmacyDiganosticProfileHeader}>
              <Grid item>
                <GetImages name="NotificationBellIcon" height="24" width="30" />
              </Grid>
              <Grid
                item
                onClick={() => dispatch(setIsSettingPreferencesOpenClose())}
              >
                <GetImages name="SettingSmallIcon" height="24" width="24" />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item className={style.pharmacyProfileDetails}>
          <Typography component={"h6"}>{profile?.name}</Typography>
          <Typography component={"p"} className={style.pharmacyDetail}>
            <GetImages name="CallOrangeIconHover" width="24" height="16" />{" "}
            {profile?.countryCode} {profile?.phoneNumber}
          </Typography>
          <Typography component={"p"} className={style.pharmacyDetail}>
            <GetImages name="LocationSmallIcon" width="24" height="16" />{" "}
            {profile?.address}
          </Typography>
          <Grid item className={style.pharmacyTimings}>
            <GetImages name="MedicineSmallIcon" width="20" height="20" />
            <Grid item className={style.pharmacyTimingsDetails}>
              {isMobile ? (
                <>
                  <Typography component={"p"} className={style.pharmacyDetail}>
                    {profile?.workingDays}
                  </Typography>
                  <Typography component={"p"} className={style.pharmacyDetail}>
                    {timeInAmPmFormat(profile?.startTime)} to{" "}
                    {timeInAmPmFormat(profile?.endTime)}
                  </Typography>
                </>
              ) : (
                <Typography component={"p"} className={style.pharmacyDetail}>
                  {profile?.workingDays}
                  <span className={style.BoldDot}>·</span>
                  {timeInAmPmFormat(profile?.startTime)} to{" "}
                  {timeInAmPmFormat(profile?.endTime)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={style.filterContainer}>
        {calendar()}
        {showDateRangeSelector && (
          <Grid
            item
            ref={dateSelectorModalRef}
            position={"absolute"}
            zIndex={100}
          >
            <DateRangePicker
              submitDateRangeValues={submitDateRangeValues}
              startDate={
                roleType === IRoleType.PHARMACY
                  ? filters.startTime
                  : filters?.startDate
              }
              endDate={
                roleType === IRoleType.PHARMACY
                  ? filters.endTime
                  : filters?.endDate
              }
              limitDays={false}
            />
          </Grid>
        )}
      </Grid>
      <Grid item p={2}>
        <Box className={style.pharmacyMonthlyAnalyticsContainer}>
          <Grid item className={style.analayticsHeader}>
            <Grid item className={style.title}>
              <Typography variant={"subtitle2"} fontWeight={400}>
                <FormattedMessage id="analytics" defaultMessage={"Analytics"} />
              </Typography>
              <Typography variant={"caption"} color={"var(--gray-600)"}>
                {DateDisplay()}
              </Typography>
            </Grid>
            <Grid item className={style.export}>
              <Typography
                component={"p"}
                onClick={!isExporting ? exportReport : undefined}
                style={{ cursor: isExporting ? "not-allowed" : "pointer" }}
              >
                <GetImages name="CSVFileIcon" width="16" height="12" />
                Export
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={style.analayticsDetails}>
            <SalesMetric
              status={IProfileAnalyticsEnum.TOTAL_SALES}
              statusCount={`₹ ${sales?.salesAmount ?? "0"}`}
            />
            {sales?.salesCount?.map((sale, index) => (
              <SalesMetric
                status={sale.status}
                statusCount={sale.statusCount}
                key={`${sale.status}-${index}`}
              />
            ))}
          </Grid>
        </Box>
      </Grid>
      {isSettingPreferencesOpenClose && <SettingPreferences />}
    </Grid>
  );
};

export default Profile;
