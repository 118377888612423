import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import GetImages from "../../GetImages";
import GenericSuccessAlert from "../../GenericSuccessAlert";
import ScheduleEventPopUps from "../../ScheduleEventPopUps";
import MakeAppointment from "../../MakeAppointment";

import { timeInAmPmFormat, transformClasses } from "../../../utils";
import useRescheduleCancelledAppointments from "../../../utils/hooks/useRescheduleCancelledAppointments";
import useAppointmentDateFilter from "../../../utils/hooks/useAppointmentDateFilter";
import { INotifyEnum, notify } from "../../../utils/toaster";

import {
  IAppointmentCardDetailEnum,
  IAppointmentCreation,
  IAppointmetEnums,
  IDoctorAvailabilityPlatform,
  IDoctorScheduleConsultationType,
  IMakeAppointmentEvent,
  IPatientAppointmentStatus,
} from "../../../models/doctor";
import { IAppointments } from "../../../models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setUniversalSearch } from "../../../store/slices/doctor/topNavigation";
import { setPrescriptionTemplateValues } from "../../../store/slices/doctor/prescription";
import { setAppointmentDetail } from "../../../store/slices/doctor/appointment";
import { setAppointmentSelection } from "../../../store/slices/doctor/schedule";
import { setVideoAppointmentDetail } from "../../../store/slices/videoAppointment";

const appointmentStatusMap = {
  [IPatientAppointmentStatus.COMPLETED]: transformClasses(
    style.status,
    style.statusCompleted
  ),
  [IPatientAppointmentStatus.CANCELLED]: transformClasses(
    style.status,
    style.statusCancelled
  ),
  [IPatientAppointmentStatus.PENDING]: transformClasses(
    style.status,
    style.statusUpcoming
  ),
};

interface IMobile {
  isMobile: boolean;
  type?: IAppointmentCardDetailEnum;
}
type IProps = IAppointments &
  IMobile & {
    isSelected?: boolean;
  };
const AppointmentDetailsView: React.FC<IProps> = (props) => {
  const {
    id,
    type_of_consultation,
    patient,
    slotStartTime,
    slotNo,
    status,
    slotDuration,
    activePrescription,
    clinic_id,
    isMobile,
    date,
    weekday,
    isSelected = false,
    type = IAppointmentCardDetailEnum.LIST,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [rescheduleAppointment, setRescheduleAppointment] =
    useState<IAppointmentCreation>({
      creation: false,
      success: false,
    });
  const [isSchedulePopUp, setSchedulePopUp] = useState({
    isOpen: false,
    detail: {
      platform: IDoctorAvailabilityPlatform.VIDEO_CALL,
      status: IPatientAppointmentStatus.COMPLETED,
    },
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { rescheduleCancelledAppointments, rescheduleSlotNo } =
    useRescheduleCancelledAppointments();

  const dispatch = useAppDispatch();
  const { selection } = useAppSelector((state) => state.schedule);
  const { appointmentDetail, makePatientAppointment } = useAppSelector(
    (state) => state.appointment
  );
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);

  const isPending = status === IPatientAppointmentStatus.PENDING;
  const isCancelled = status === IPatientAppointmentStatus.CANCELLED;
  const isCompleted = status === IPatientAppointmentStatus.COMPLETED;
  const isVideoCall =
    type_of_consultation === IDoctorScheduleConsultationType.VIDEO_CALL;
  const isClinicVisit =
    type_of_consultation === IDoctorScheduleConsultationType.IN_CLINIC;

  const isCancelledOrCompleted = isCancelled || isCompleted;

  const { applyDateRangeValues } = useAppointmentDateFilter();

  const handleScheduleStatusPopUps = (
    status: IPatientAppointmentStatus,
    platform: IDoctorScheduleConsultationType
  ): void => {
    setSchedulePopUp({
      isOpen: true,
      detail: {
        status: status,
        platform:
          platform === IDoctorScheduleConsultationType.VIDEO_CALL
            ? IDoctorAvailabilityPlatform.VIDEO_CALL
            : IDoctorAvailabilityPlatform.IN_CLINIC,
      },
    });
  };

  const handleScheduleDetail = (): void => {
    if (isMobile) {
      handleScheduleStatusPopUps(
        status,
        type_of_consultation ?? IDoctorScheduleConsultationType.VIDEO_CALL
      );
    } else if (isShowUniversalSearch) {
      handleScheduleStatusPopUps(
        status,
        type_of_consultation ?? IDoctorScheduleConsultationType.VIDEO_CALL
      );
    }
    dispatch(
      setAppointmentDetail({
        id,
        type_of_consultation,
        patient,
        slotStartTime,
        slotNo,
        status,
        slotDuration,
        activePrescription,
        clinic_id,
      })
    );
    dispatch(
      setVideoAppointmentDetail({
        id,
        type_of_consultation,
        patient,
        slotStartTime,
        slotNo,
        status,
        slotDuration,
        activePrescription,
        clinic_id,
      })
    );
    if (location.pathname == "/search") {
      navigate("/schedule");
    }
  };

  const handleAppointmentSelection = (isChecked: boolean): void => {
    dispatch(setAppointmentSelection({ [id]: isChecked }));
  };

  const closeRescheduleModal = (): void => {
    const rescheduleModal: IAppointmentCreation = {
      creation: false,
      success: false,
    };
    for (const key in rescheduleAppointment) {
      if (key in rescheduleModal) {
        rescheduleModal[key as keyof IAppointmentCreation] = false;
      }
    }
    setRescheduleAppointment(rescheduleModal);
  };

  const openAppointmentRescheduleDetailHandler = async (
    type: IAppointmetEnums
  ) => {
    switch (type) {
      case IAppointmetEnums.CREATION:
        setRescheduleAppointment({
          ...rescheduleAppointment,
          creation: true,
        });
        return;
      case IAppointmetEnums.SUCCESS:
        const response = await rescheduleCancelledAppointments();
        if ("message" in response!) {
          notify(INotifyEnum.ERROR, response?.message);
          return;
        }
        setRescheduleAppointment({
          ...rescheduleAppointment,
          success: true,
        });
        closeSchedulePopUpHandler();
        return;
      default:
        closeRescheduleModal();
        return;
    }
  };

  const closeSchedulePopUpHandler = (): void => {
    setSchedulePopUp({ ...isSchedulePopUp, isOpen: false });
  };

  const renderAppointmentStatus = (): JSX.Element => {
    return (
      <Grid item>
        <Typography
          component={"p"}
          className={
            (isPending || isCancelled || isCompleted) && !activePrescription
              ? transformClasses(style.statusText, appointmentStatusMap[status])
              : {}
          }
          onClick={handleScheduleDetail}
        >
          {status === IPatientAppointmentStatus.PENDING ? (
            activePrescription ? (
              <GetImages
                name={"PrescriptionDefaultSmallIcon"}
                width={"24"}
                height={"24"}
              />
            ) : null
          ) : (
            status
          )}
        </Typography>
      </Grid>
    );
  };

  useEffect(() => {
    if (rescheduleAppointment.creation) {
      setSelectedTab(
        isSchedulePopUp.detail?.platform ===
          IDoctorAvailabilityPlatform.VIDEO_CALL
          ? IMakeAppointmentEvent.VIDEO_CALL
          : IMakeAppointmentEvent.IN_CLINIC
      );
    }
  }, [rescheduleAppointment]);

  return (
    <Grid
      item
      className={transformClasses(
        style.patientDetailContainer,
        isSelected && style.active
      )}
    >
      <Grid item className={style.patientContainer}>
        {!isCancelledOrCompleted && selection.isSelectionOn && (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Checkbox
                checked={!!selection.appointmentSelection[id]}
                onChange={(e) => handleAppointmentSelection(e.target.checked)}
                checkedIcon={
                  <GetImages name="TickOrangeIcon" width="24" height="24" />
                }
                icon={<GetImages name="RadioBtnIcon" width="24" height="24" />}
                sx={{ padding: 0 }}
              />
            }
            label={""}
          />
        )}
        <Grid
          item
          className={style.patientNameContainer}
          onClick={handleScheduleDetail}
        >
          <Grid item>
            <Typography component={"p"} className={style.patientName}>
              {patient?.name}
            </Typography>
            <Grid item className={style.slotRecordContainer}>
              <Typography
                component={"p"}
                className={transformClasses(
                  style.slotRecordNumber,
                  style.slotChip
                )}
              >
                {isVideoCall
                  ? timeInAmPmFormat(slotStartTime ?? "")
                  : `Slot ${slotNo}`}
              </Typography>
              <Typography component={"span"} className={style.slotRecordNumber}>
                {weekday
                  ? `${weekday.charAt(0).toUpperCase() + weekday.slice(1)}`
                  : ""}
              </Typography>
              <Typography
                component={"p"}
                className={transformClasses(style.clinicRecordNumber)}
              >
                {type === IAppointmentCardDetailEnum.SEARCH ? (
                  !isVideoCall ? (
                    "Clinic"
                  ) : null
                ) : (
                  <>
                    {patient?.records}{" "}
                    <FormattedMessage id="records" defaultMessage="Records" />
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
          {isPending && isVideoCall ? (
            <Grid
              item
              className={activePrescription ? "" : style.videoIconContainer}
            >
              <GetImages
                name={
                  activePrescription
                    ? "PrescriptionDefaultSmallIcon"
                    : "VideoWhiteIcon"
                }
                width={"20"}
                height={"20"}
              />
            </Grid>
          ) : (isPending && isClinicVisit) || isCompleted || isCancelled ? (
            renderAppointmentStatus()
          ) : (
            <Grid item className={style.messageUnreadContainer}>
              <Typography component={"p"} className={style.messageUnreadTime}>
                {timeInAmPmFormat(slotStartTime)}
              </Typography>{" "}
              <Typography component={"p"} className={style.messageUnreadCount}>
                5
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      {isSchedulePopUp.isOpen && (
        <ScheduleEventPopUps
          openAppointmentSlotHandler={openAppointmentRescheduleDetailHandler}
          isOpen={isSchedulePopUp.isOpen}
          closeHandler={closeSchedulePopUpHandler}
          platform={isSchedulePopUp.detail?.platform}
          status={isSchedulePopUp.detail?.status}
        />
      )}
      {rescheduleAppointment.creation && (
        <MakeAppointment
          isReschedule={rescheduleAppointment.creation}
          isOpen={rescheduleAppointment.creation}
          platform={isSchedulePopUp.detail?.platform}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          closeScheduleModal={closeRescheduleModal}
          openScheduleModalHandler={openAppointmentRescheduleDetailHandler}
        />
      )}
      {rescheduleAppointment.success && (
        <GenericSuccessAlert
          type={isSchedulePopUp.detail?.platform}
          slotNumber={+rescheduleSlotNo}
          name={appointmentDetail?.patient?.name}
          age={appointmentDetail?.patient?.age.toString()}
          date={makePatientAppointment?.dateSlotTime}
          isOpen={rescheduleAppointment.success}
          closeScheduleModal={closeRescheduleModal}
        />
      )}
    </Grid>
  );
};

export default AppointmentDetailsView;
