import React from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import Button from "../Button";
import GetImages from "../GetImages";

import style from "./index.module.css";

import {
  formatTimestamp,
  isClinic,
  openClinicMapLocation,
  timeInAmPmFormat,
  transformClasses,
} from "../../utils";
import { storage } from "../../utils/Storage";
import {
  slotBookedFromDoctor,
  slotBookedFromPatient,
  slotBookVideoAppointmentDoctor,
  slotBookVideoAppointmentPatient,
} from "../../utils/text";

import { IRoleType } from "../../models/role";
import { IDoctorAvailabilityPlatform } from "../../models/doctor";
import { IMedicineModuleEnums, ITestBookingEnums } from "../../models/patient";
import { IButtonVariant } from "../../models/button";
import { IMedicineOrderType } from "../../models/ApiRequestResponse/patient";

import { useAppDispatch } from "../../store/hooks";
import { setAppointmentDetail } from "../../store/slices/doctor/appointment";
import { fetchScheduleAppointmentList } from "../../store/slices/doctor/schedule";
import { setVideoAppointmentDetail } from "../../store/slices/videoAppointment";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "1.2rem",
    padding: "2rem 1.6rem 1.6rem 1.6rem",
    textAlign: "center",
    width: "32.3rem",
    margin: "0",
    backgroundColor: "var(--neutral-100)",
  },
}));

interface IProps {
  type?: IDoctorAvailabilityPlatform | IMedicineModuleEnums | ITestBookingEnums;
  deliveryType?: IMedicineOrderType;
  slotNumber?: number;
  name?: string;
  age?: string;
  bio?: string;
  date?: string;
  isOpen: boolean;
  closeScheduleModal: () => void;
  btnLabel?: string;
  orderId?: string;
  testNames?: string[];
  bookingTimestamp?: number;
  start_time?: string;
  end_time?: string;
  dignosticName?: string;
  dignosticAddress?: string;
  latitude?: number;
  longitude?: number;
}

interface ITypeOfConsultation {
  [key: string]: string;
}

interface IConfirmationLabel {
  [key: string]: ITypeOfConsultation;
}

const getBookingConfirmationByConsultation: IConfirmationLabel = {
  [IRoleType.DOCTOR]: {
    [IDoctorAvailabilityPlatform.VIDEO_CALL]: slotBookVideoAppointmentDoctor,
    [IDoctorAvailabilityPlatform.IN_CLINIC]: slotBookedFromDoctor,
  },
  [IRoleType.PATIENT]: {
    [IDoctorAvailabilityPlatform.VIDEO_CALL]: slotBookVideoAppointmentPatient,
    [IDoctorAvailabilityPlatform.IN_CLINIC]: slotBookedFromPatient,
  },
  [IRoleType.PHARMACY]: {
    [IDoctorAvailabilityPlatform.IN_CLINIC]: slotBookedFromDoctor,
  },
};

const GenericSuccessAlert: React.FC<IProps> = (props) => {
  const {
    type,
    deliveryType,
    slotNumber,
    name,
    age,
    date,
    isOpen,
    closeScheduleModal,
    btnLabel = "done",
    bio,
    orderId,
    testNames,
    bookingTimestamp,
    start_time,
    end_time,
    dignosticName,
    dignosticAddress,
    latitude,
    longitude,
  } = props;

  const roleType = storage.getSessionToken("roleType");
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isClinicRole = isClinic();

  const closeModalHandler = () => {
    if (roleType === IRoleType.DOCTOR || isClinicRole) {
      dispatch(setAppointmentDetail({}));
      dispatch(setVideoAppointmentDetail({}));
      dispatch(fetchScheduleAppointmentList({}));
    }
    closeScheduleModal();
  };

  return (
    <CustomDialog open={isOpen} sx={{ borderRadius: "1.2rem" }}>
      {(roleType == IRoleType.DOCTOR || roleType === IRoleType.PHARMACY) && (
        <DialogTitle sx={{ padding: 0 }}>
          <FormattedMessage
            id="appointment_succesful"
            defaultMessage="Appointment Succesful"
          />
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 0 }}>
        <Grid item sx={{ margin: "1.6rem 0rem" }}>
          <Grid item className={style.slotContainer}>
            <Grid item className={style.slotIconContainer}>
              <GetImages name="SlotAllocationIcon" width="70" height="70" />
            </Grid>
            <Typography
              component={"p"}
              className={transformClasses(
                style.postionSlotNumber,
                style.slotNumber
              )}
            >
              {type === IDoctorAvailabilityPlatform.VIDEO_CALL ||
              type === IMedicineModuleEnums.MEDICINE ||
              type === ITestBookingEnums.TEST_BOOKING ? (
                <GetImages
                  name="AppointmentBookedLikeIcon"
                  width="20"
                  height="20"
                />
              ) : (
                slotNumber
              )}
            </Typography>
          </Grid>
          <Grid item>
            {type && type === IDoctorAvailabilityPlatform.IN_CLINIC && (
              <Typography component={"p"} className={style.slotNumerAllotted}>
                <FormattedMessage
                  id="slot_number_allotted"
                  defaultMessage="Slot Number Allotted"
                />
              </Typography>
            )}
            <Typography
              component={"p"}
              className={style.successMessage}
              sx={{
                fontWeight: type === ITestBookingEnums.TEST_BOOKING ? 500 : 400,
              }}
            >
              {type === IMedicineModuleEnums.MEDICINE
                ? intl.formatMessage({
                    id: "medicine_order_successful",
                    defaultMessage: "Your order for Medicine is successful",
                  })
                : type === ITestBookingEnums.TEST_BOOKING
                ? intl.formatMessage({
                    id: "test_booking_successful",
                    defaultMessage: "Your Test is successfully Booked",
                  })
                : roleType && type
                ? getBookingConfirmationByConsultation[roleType][type]
                : ""}
            </Typography>
            <Typography component={"p"} className={style.patientNameAge}>
              {name} {roleType == IRoleType.DOCTOR && `, ${age}`}
            </Typography>
            {roleType === IRoleType.PATIENT && (
              <Typography component={"p"} className={style.bio}>
                {bio}
              </Typography>
            )}
            {type === ITestBookingEnums.TEST_BOOKING ? (
              <>
                <Grid className={style.testBookingGrid}>
                  <Grid className={style.leftContent}>
                    <Typography component={"p"} className={style.dignosticName}>
                      {dignosticName}
                    </Typography>
                    <Typography
                      component={"p"}
                      className={style.testbookingbio}
                    >
                      {dignosticAddress}
                    </Typography>
                    <Typography
                      component={"p"}
                      className={style.testbookingbio}
                    >
                      {testNames &&
                        testNames?.length > 0 &&
                        testNames.join(" & ")}
                    </Typography>
                    {start_time && end_time && bookingTimestamp ? (
                      <Typography
                        component={"p"}
                        className={style.testbookingbio}
                      >
                        {`${timeInAmPmFormat(start_time)} - ${timeInAmPmFormat(
                          end_time
                        )} ${formatTimestamp(bookingTimestamp, "D MMM YYYY")}`}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Box
                      className={style.locationBox}
                      onClick={() =>
                        openClinicMapLocation(latitude!, longitude!)
                      }
                    >
                      <GetImages
                        name="LocationAccessSmallIcon"
                        width="26"
                        height="20"
                      />
                      <Typography className={style.maps}>
                        {intl.formatMessage({
                          id: "maps",
                          defaultMessage: "Maps",
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid>
                    <Grid item className={style.btnWrapper}>
                      <Button variant={IButtonVariant.WHITE}>
                        <GetImages
                          name="TelePhoneIcon"
                          width="26"
                          height="20"
                        />
                        <FormattedMessage id="call" defaultMessage="Call" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Typography component={"p"} className={style.orederId}>
                  {intl.formatMessage({
                    id: "order_id",
                    defaultMessage: "Order ID",
                  })}{" "}
                  {orderId}
                </Typography>
              </>
            ) : (
              ""
            )}
            {(!deliveryType ||
              deliveryType === IMedicineOrderType.HOME_DELIVERY) && (
              <Typography component={"p"} className={style.slotWithTime}>
                {date}
              </Typography>
            )}
            {deliveryType === IMedicineOrderType.PICKUP && (
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1.6rem",
                  marginTop: "1.6rem",
                }}
              >
                <Button variant={IButtonVariant.WHITE}>
                  <GetImages
                    name="CallOrangeIconHover"
                    width="22"
                    height="20"
                  />
                  <FormattedMessage defaultMessage={"Call"} id="call" />
                </Button>
                <Button
                  variant={IButtonVariant.WHITE}
                  btnTrigger={() =>
                    openClinicMapLocation(latitude!, longitude!)
                  }
                >
                  <GetImages name="LocationSmallIcon" width="22" height="20" />
                  <FormattedMessage defaultMessage={"View Map"} id="view_map" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <Button btnTrigger={closeModalHandler}>
        <FormattedMessage id={btnLabel} defaultMessage="Button Label" />
      </Button>
    </CustomDialog>
  );
};

export default GenericSuccessAlert;
