import { defaultCalendarDates, LIMIT } from "../..";
import {
  IDiagnosticProfileResponse,
  IPharmacyDiagnosticOrderListResponse,
} from "../../../models/ApiRequestResponse/diagnostic";
import {
  IOrderStatusEnum,
  IPharmacySalesExportResponse,
  IProfileSalesResponse,
} from "../../../models/ApiRequestResponse/pharmacy";
import { IOrderDateRangeParams } from "../../../models/doctor";
import { GBSCError } from "../../../models/GBSCError";

import { get, put } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getPharmacyOrderListingApi = async ({
  status,
  isPending,
  type,
  startTime,
  endTime,
  keyword,
  offset,
}: IOrderDateRangeParams): Promise<
  IPharmacyDiagnosticOrderListResponse | GBSCError
> => {
  try {
    let url = `${APIEndpoint.pharmacy.orders}?limit=${LIMIT}&offset=${offset}`;
    url +=
      startTime && endTime ? `&startTime=${startTime}&endTime=${endTime}` : "";
    url += status ? `&status=${status}` : "";
    url += isPending ? `&isPending=${isPending}` : "";
    url += type ? `&type=${type}` : "";
    url += keyword ? `&keyword=${keyword ?? ""}` : "";
    const response = await get<IPharmacyDiagnosticOrderListResponse>(url);
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getPharmacyProfileApi = async (): Promise<
  IDiagnosticProfileResponse | GBSCError
> => {
  try {
    const response = await get<IDiagnosticProfileResponse>(
      APIEndpoint.pharmacy.profile
    );
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const handleUpdateOrderApi = async (
  requestBody: any
): Promise<any | GBSCError> => {
  try {
    const response = await put<any>(
      APIEndpoint.pharmacy.updateOrder,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getPharmacyProfileMonthlyAnalysisApi = async ({
  startTime,
  endTime,
}: IOrderDateRangeParams): Promise<IProfileSalesResponse | GBSCError> => {
  let url = `${APIEndpoint.pharmacy.sales}?startDate=${
    startTime ?? 0
  }&endDate=${endTime ?? 0}`;

  try {
    const response = await get<IProfileSalesResponse>(url);
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getPharmacyProfileMonthlyAnalysisExportCSVApi = async ({
  startTime,
  endTime,
}: IOrderDateRangeParams): Promise<
  IPharmacySalesExportResponse | GBSCError
> => {
  let url = `${APIEndpoint.pharmacy.salesExport}?startDate=${
    startTime ?? 0
  }&endDate=${endTime ?? 0}`;
  try {
    const response = await get<IPharmacySalesExportResponse>(url);
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
